import * as React from "react";
import type { SVGProps } from "react";
const SvgPhone2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M225.279 356.349V159.015h149.54v282.423h-133.97m42.724-230.284h32.949m.362 170.54c0 9.199-7.457 16.656-16.656 16.656-9.198 0-16.655-7.457-16.655-16.656 0-9.199 7.457-16.656 16.655-16.656 9.199 0 16.656 7.457 16.656 16.656"
    />
  </svg>
);
export default SvgPhone2;
