import * as React from "react";
import type { SVGProps } from "react";
const SvgEqualsSign = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M453.805 248.454a77.572 77.572 0 0 1-52.698 21.179l-254.912 1.575v-63.36h246.158v184.304H150.747v-61.96h262.966c8.42 0 16.498 3.332 22.469 9.269a31.85 31.85 0 0 1 9.395 22.411v30.28"
    />
  </svg>
);
export default SvgEqualsSign;
