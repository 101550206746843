import * as React from "react";
import type { SVGProps } from "react";
const SvgSearchAndRescueHelicopter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M73.52 278.882c0 10.939 8.988 19.802 20.08 19.802h46.138c5.629 0 10.632-3.54 12.444-8.793l14.882-43.239-61.257-.001c-30.155 0-54.61-24.106-54.61-53.843 0-29.737 24.455-53.843 54.61-53.843h103.827v-25.278c0-7.538 4.989-13.642 12.639-13.642h2.187V71.117H25m147.317 129.69 23.131 22.811m-1.476 39.895v170.295l88.61 76.529-18.561 18.296h-13.962v-21.459c0-6.062-4.975-10.967-11.12-10.967a11.19 11.19 0 0 0-9.225 4.836l.056-.07h-78.856c-8.682 0-11.998 4.18-12.583 12.402v15.328l-14.13.195-18.84-18.575 72.07-62.246m26.016 80.82h-65.103m201.593-230.214h-91.593c-5.239 0-9.977-3.066-12.053-7.817l-19.383-44.215h31.255c31.116 0 58.358-19.662 68.154-48.2l189.484-15.161 15.342 25.11c3.372 5.533 9.447 8.905 15.997 8.905h21.961l-24.902-34.098L575 77.513c-10.298 0-19.829 5.336-25.11 14.06l-42.668 70.509-251.005-6.076v-42.333c0-7.539-4.988-13.642-12.638-13.642h-2.188V71.103h199.46m-262.542 113.4c0 12.267-10.082 22.212-22.519 22.212-12.436 0-22.518-9.945-22.518-22.212s10.082-22.212 22.518-22.212c12.437 0 22.519 9.945 22.519 22.212"
    />
  </svg>
);
export default SvgSearchAndRescueHelicopter;
