import * as React from "react";
import type { SVGProps } from "react";
const SvgThumbsUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M300.031 40.651C148.153 40.651 25 143.392 25 270.218c0 126.826 123.153 229.567 275.031 229.567 30.314 0 59.51-4.145 86.809-11.689l61.855 60.342c5.324 4.802 5.795 5.323 8.612 7.817 7.532 6.601 10.969 1.365 10.969-3.536V451.764h-.05C533.196 409.787 575 344.072 575 270.205c0-126.813-123.116-229.566-274.981-229.566z"
    />
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M330.307 149.1c24.383-14.133 41.754 37.126 20.995 61.384-4.703 5.509.236 4.331 9.939 4.331l32.299.049c57.339.05 36.915 157.065-7.631 156.879l-77.167-.285c-24.246-.137-49.522-16.392-57.153-30.575V215.758z"
    />
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M188.827 200.818h48.021c8.102 0 14.741 6.639 14.741 14.791v129.878c0 8.103-6.639 14.741-14.741 14.741h-48.021c-8.102 0-14.741-6.638-14.741-14.741V215.609c0-8.152 6.639-14.791 14.741-14.791"
    />
  </svg>
);
export default SvgThumbsUp;
