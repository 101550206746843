import * as React from "react";
import type { SVGProps } from "react";
const SvgLineGraph1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M25 99.24v401.52h550m-351.286-.001H78.326s11.715-242.761 74.021-242.761c62.307 0 71.367 242.761 71.367 242.761m171.441.001H193.193s31.724-367.969 100.981-367.969c69.258 0 100.981 367.969 100.981 367.969m126.551-1.219H319.744s51.31-168.505 100.981-168.505c49.67 0 100.981 168.505 100.981 168.505"
    />
  </svg>
);
export default SvgLineGraph1;
