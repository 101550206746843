import * as React from "react";
import type { SVGProps } from "react";
const SvgTrophy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M157.864 496.56V575m0-78.44h156.073m-156.073 0c29.531-9.174 50.553-23.842 65.239-39.937M157.864 575h-28.292m28.292 0h284.229M165.232 309.315c-9.338-20.438-12.781-40.218-12.905-53.61V94.212m12.905 215.103c13.825 30.262 40.574 61.966 88.605 76.507-.124 10.946-3.407 27.282-12.3 44.423m-76.305-120.93c-41.098-7.074-123.604-46.958-139.384-150.064m126.479-65.04V25h101.51m-101.51 69.212C125.16 42.505 25.959 39.179 23.591 128.975M442.093 496.56c-76.041-23.624-95.666-83.668-95.973-110.738 48.031-14.541 74.78-46.245 88.605-76.507m7.368 187.245V575m0-78.44H346.12M442.093 575h28.292m-35.66-265.685c9.338-20.438 12.781-40.218 12.905-53.61v-40.374m-12.905 93.984c43.06-7.412 131.579-50.842 141.176-165.271 8.814-105.082-99.664-104.279-128.271-49.833m0 0V25H253.837M447.63 94.212v80.746M253.837 25v103.975m0 37.504c0 48.38 0 111.925 16.211 183.54"
    />
  </svg>
);
export default SvgTrophy;
