import * as React from "react";
import type { SVGProps } from "react";
const SvgHeadphones = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M175.494 217.308c-5.431 13.397-8.689 27.881-8.689 43.088v111.883a25.961 25.961 0 0 0 26.069 26.07h24.259m168.728-.362h21.362a25.961 25.961 0 0 0 26.07-26.069V260.035c0-61.916-50.329-112.245-112.245-112.245h-42.001c-15.932 0-30.777 3.259-44.536 9.052M343.497 452.3h-17.742V344.4h17.742c21.725 0 39.467 17.742 39.467 39.467v29.328c0 21.725-17.742 39.467-39.467 39.467zm-72.772-108.264h2.896v107.901h-2.896c-29.691 0-53.95-24.26-53.95-53.95 0-29.691 24.259-53.951 53.95-53.951"
    />
  </svg>
);
export default SvgHeadphones;
