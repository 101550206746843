import * as React from "react";
import type { SVGProps } from "react";
const SvgToyRobot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M363.505 573.573h-35.962l26.258-46.523v-74.494H246.199v74.494l26.258 46.523H188.259l27.971-47.665v-75.35h-14.556V335.82h-19.98v-25.402c-23.689 17.696-22.262 58.51-22.262 58.51h22.833v19.694h-17.41c23.689 15.127 7.135 45.952 7.135 45.952l-18.837-5.422s13.985-16.555-4.567-31.396l-7.706.285c-19.409 13.985-6.28 30.54-6.28 30.54l-16.839 7.991c-15.413-21.406 4.281-46.523 4.281-46.523h-20.265v-20.264h17.696c0-87.338 61.365-101.038 61.365-101.038v-20.836h19.979v-13.985h65.076v-28.542h-43.384v-41.671h-12.844c-4.852 0-8.848-3.996-8.848-8.848v-15.412c0-4.853 3.996-8.848 8.848-8.848h11.988V87.792h62.792V48.69h-14.271V25h59.938v23.69h-14.271v39.102h62.506v42.527h11.988c4.852 0 8.848 3.996 8.848 8.848v15.413c0 4.852-3.996 8.848-8.848 8.848h-12.844v41.671h-43.384v28.541h65.076v13.986h19.979v20.835s62.221 10.561 61.365 101.038h17.696v20.265h-20.265s19.694 24.831 4.281 46.523l-16.839-7.992s13.129-16.554-6.28-30.539l-7.706-.286c-18.552 14.842-4.567 31.396-4.567 31.396l-18.837 5.423s-16.554-30.825 7.135-45.952h-17.41v-19.694h22.833s1.713-40.815-22.262-58.511v25.402h-19.98v114.738H383.77v75.351L412.597 575M287.015 156.006v23.119m25.403-23.119v23.119m-50.807-23.119h75.921v23.119h-75.921zm11.417-18.836c-6.279 0-11.417-5.423-11.417-11.702 0-6.28 5.138-11.417 11.417-11.417 6.565 0 11.702 5.137 11.702 11.417 0 6.564-5.137 11.987-11.702 11.702m52.802 0c-6.279 0-11.416-5.423-11.416-11.702 0-6.28 5.137-11.417 11.416-11.417 6.565 0 11.702 5.137 11.702 11.417 0 6.564-5.137 11.987-11.702 11.702"
    />
  </svg>
);
export default SvgToyRobot;
