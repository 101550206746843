import * as React from "react";
import type { SVGProps } from "react";
const SvgScreenWithChart1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M107.737 119.183v266.415a13.79 13.79 0 0 1-13.745 13.745h-4.596a13.743 13.743 0 0 1-13.745-13.745 13.705 13.705 0 0 1 4.013-9.732 13.712 13.712 0 0 1 9.732-4.012h430.672m-20.279-62.188-69.488-7.165-53.716 22.352-53.715-27.399-53.716 10.139-53.716 9.148-53.715-3.064-84.585 16.178m196.748 199.496v-83.773a11.987 11.987 0 0 0-11.987-11.987H56.905A31.953 31.953 0 0 1 25 401.641V102.599a31.905 31.905 0 0 1 31.905-31.95h486.19A31.904 31.904 0 0 1 575 102.6v299.042a31.95 31.95 0 0 1-31.905 31.95h-180.75a11.987 11.987 0 0 0-11.987 11.987v83.773z"
    />
  </svg>
);
export default SvgScreenWithChart1;
