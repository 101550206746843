import * as React from "react";
import type { SVGProps } from "react";
const SvgCellTower = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M246.772 206.517c-13.631-13.631-22.053-32.452-22.053-53.248s8.422-39.616 22.053-53.247m106.496 0c13.631 13.631 22.054 32.451 22.054 53.247s-8.423 39.617-22.054 53.248m-143.996 37.481c-23.251-23.251-37.641-55.343-37.641-90.748 0-35.406 14.39-67.498 37.641-90.749m181.477.02C414 85.77 428.39 117.864 428.39 153.269c0 35.406-14.39 67.498-37.641 90.749M171.87 281.659c-32.93-32.871-53.347-78.295-53.347-128.389 0-50.095 20.357-95.4 53.208-128.27m256.539 0c32.85 32.85 53.207 78.235 53.207 128.27 0 50.034-20.357 95.398-53.207 128.269M136.505 575c99.809-155.552 138.129-292.324 155.592-401.713m170.72 401.712c-99.73-155.412-138.069-292.104-155.552-401.433m-32.073 187.323h49.636m-80.131 82.986h110.626M211.966 526.86h176.089m-66.5-373.591c0 11.893-9.641 21.534-21.535 21.534-11.893 0-21.534-9.641-21.534-21.534 0-11.894 9.641-21.535 21.534-21.535 11.894 0 21.535 9.641 21.535 21.535"
    />
  </svg>
);
export default SvgCellTower;
