import * as React from "react";
import type { SVGProps } from "react";
const SvgCloud = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M571.669 361.065c2.12-1.287 3.331-2.726 3.331-4.165 0-6.435-22.412-11.735-51.335-12.417-1.136-6.511-6.739-11.509-13.629-11.509s-12.948 5.376-13.705 12.191c-14.31 1.135-25.895 3.482-32.33 6.435-1.363-8.556-8.783-15.143-17.794-15.143-9.01 0-14.613 5.073-17.036 12.19-3.028-7.193-9.616-12.19-17.263-12.19-7.647 0-3.256.227-4.77.682-4.392-21.276-23.245-37.253-45.884-37.253s-38.994 14.008-44.824 33.164c-3.861-1.666-8.177-2.574-12.644-2.574-4.467 0-1.893 0-2.877.151.757-4.619 1.211-9.464 1.211-14.386 0-42.704-30.438-77.306-68.068-77.306-37.631 0-67.16 33.618-67.993 75.489a47.528 47.528 0 0 0-11.963-1.514c-6.966 0-13.554 1.514-19.459 4.088-6.588-10.524-16.658-17.263-27.94-17.263-18.55 0-33.845 18.172-35.662 41.492-2.65-.378-5.376-.53-8.101-.53-19.535 0-35.662 8.859-37.934 20.292h546.669z"
    />
  </svg>
);
export default SvgCloud;
