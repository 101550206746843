import * as React from "react";
import type { SVGProps } from "react";
const SvgEiffelTower = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M167.941 575c43.884-56.453 103.923-234.982 104.496-444.254V90.751l18.385-18.397L295.424 25l5.981 47.354 19.764 19.775s.315 192.102 16.867 244.981h32.18l.234 13.853-156.405.456v28.968l165.06-2.336v11.821h-29.096s8.807 74.091 18.011 86.507l43.675-.455v14.25l-232.038-.479-1.075 31.749 249.209-1.846.736 15.886-26.2-.07s12.794 19.053 29.732 39.586"
    />
  </svg>
);
export default SvgEiffelTower;
