import * as React from "react";
import type { SVGProps } from "react";
const SvgSimplifiedGlobe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M188.207 25v54.842c-93.6 40.604-158.725 131.04-158.725 235.978C29.482 458.988 150.504 575 300 575c149.497 0 270.518-116.012 270.518-259.18 0-90.173-47.987-169.535-121.021-215.94-172.436-102.828-277.11 78.308-277.11 215.94 0 119.439 57.215 216.467 127.613 216.467s127.349-97.028 127.349-216.467c0-81.736-26.63-152.925-66.179-189.837-32.958-31.112-61.17-10.81-61.17 27.421v281.855c0 76.462 108.893 95.182 173.49 11.865a5.8 5.8 0 0 0-4.482-9.492h-339.07a20.049 20.049 0 0 1-16.611-9.228c-20.038-30.849-29.003-54.315-32.958-90.7A19.773 19.773 0 0 1 99.88 315.82h401.031a19.775 19.775 0 0 0 19.775-21.621c-3.955-41.395-22.412-73.034-34.277-90.963a19.777 19.777 0 0 0-16.347-8.965H133.629z"
    />
  </svg>
);
export default SvgSimplifiedGlobe;
