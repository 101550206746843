import * as React from "react";
import type { SVGProps } from "react";
const Svg9Drop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M66.681 363.159h24.26a18.693 18.693 0 0 0 12.824-5.125 18.71 18.71 0 0 0 5.809-12.534C118.429 189.403 245.655 188.419 321.229 25c78.524 169.818 213.069 164.465 212.085 339.697C532.699 481.17 437.694 575 321.29 575h-.184c-88.979 0-159.633-28.365-191.056-106.136-3.835-9.55-5.037-17.495-3.479-27.669 1.557-10.174 3.799-16.134 10.316-24.098 5.412-6.669 10.998-11.391 18.755-15.074 7.756-3.684 19.505-3.692 28.091-3.67l106.503 2.03m-1.736-.029c30.53 1.325 46.956.331 71.18-12.753 26.714-17.059 41.314-48.859 41.314-88.277 0-55.981-25.219-102.19-85.117-102.19-45.13 0-75.991 29.316-75.991 67.906 0 42.068 32.023 63.93 67.695 63.93 21.57 0 41.48-12.09 50.938-25.506v4.141c0 29.978-17.754 56.974-49.61 56.974-17.422 0-28.704-5.3-39.323-15.071l-18.749 31.302m67.032-142.601c-19.081 0-29.369 13.25-29.369 25.009 0 17.887 14.768 25.672 31.028 25.672a42.362 42.362 0 0 0 25.717-8.778 15.08 15.08 0 0 0 5.113-7.044c.974-2.798 1.1-5.821.363-8.691-3.651-13.415-13.274-26.168-32.852-26.168"
    />
  </svg>
);
export default Svg9Drop;
