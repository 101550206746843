import * as React from "react";
import type { SVGProps } from "react";
const SvgChristmasTree = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m306.426 76.311 55.631 81.984c1.1 1.62-.061 3.81-2.019 3.81h-89.093c-.806 0-1.56.398-2.015 1.063l-59.478 87.042c-1.107 1.62.053 3.817 2.014 3.817h164.043c.862 0 1.66.455 2.099 1.196l46.133 77.85c.964 1.626-.208 3.684-2.099 3.684H208.15a2.44 2.44 0 0 0-2.031 1.086l-58.028 87.042c-1.081 1.622.082 3.794 2.031 3.794h274.465a2.44 2.44 0 0 1 2.057 1.127l53.572 83.978c1.036 1.625-.131 3.753-2.057 3.753H159.001a2.44 2.44 0 0 0-1.915.928l-44.496 56.361a2.44 2.44 0 0 1-1.915.928H38.547M306.426 76.311c-6.865-8.336-16.366-29.195-1.923-48.203a2.234 2.234 0 0 1 3.658.129c8.393 12.67 17.74 36.618-1.735 48.074m0 0-67.99 103.462c-1.066 1.623.098 3.78 2.039 3.78h113.651a2.44 2.44 0 0 1 2.046 1.112l53.518 82.446c1.053 1.623-.112 3.768-2.047 3.768H228.008a2.44 2.44 0 0 0-1.979 1.012l-56.224 77.849c-1.166 1.614-.013 3.869 1.978 3.869h238.92c.884 0 1.7.479 2.131 1.252l49.369 88.574c.906 1.626-.269 3.628-2.131 3.628H168.231a2.44 2.44 0 0 0-1.952.976l-65.282 87.042c-1.206 1.609-.058 3.904 1.952 3.904h360.203c.684 0 1.336.287 1.798.791l32.255 35.187a2.439 2.439 0 0 0 1.798.791h62.45"
    />
  </svg>
);
export default SvgChristmasTree;
