import * as React from "react";
import type { SVGProps } from "react";
const SvgOlympicRings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M25 342.799h85.609c41.817 0 76.665-30.034 84.138-69.696 4.356-23.247-33.059-8.116-52.317 11.188-12.747 12.747-22.697 31.363-22.697 58.508 0 46.861 37.645 84.918 84.322 85.606 47.641.642 86.845-38.012 86.845-85.606a85.426 85.426 0 0 0-25.036-60.525c-20.679-20.68-53.922-31.317-50.3-10.18 6.695 38.929 41.222 70.705 84.46 70.705 59.195-.046 80.425-50.163 84.093-69.696 4.402-23.247-33.014-8.116-52.317 11.188-12.747 12.747-22.697 31.363-22.697 58.508 0 46.861 37.645 84.918 84.368 85.606 47.595.642 86.844-38.012 86.844-85.606a85.264 85.264 0 0 0-6.497-32.767 85.268 85.268 0 0 0-18.584-27.758c-20.634-20.68-53.876-31.317-50.254-10.18 6.694 38.929 41.221 70.705 84.414 70.705 47.273 0 85.606-38.333 85.606-85.607a85.476 85.476 0 0 0-24.936-60.475 85.484 85.484 0 0 0-60.395-25.131c-41.909-.137-76.848 29.896-84.368 69.604l-21.184-.137a85.513 85.513 0 0 0-83.818-69.421c-41.955-.137-76.894 29.896-84.414 69.604l-21.183-.138c-7.566-39.57-42.322-69.466-84.093-69.466-47.274 0-85.606 38.333-85.606 85.606 0 28.612 13.939 49.933 35.398 65.431h-.046s11.554 9.584 24.714 9.125c7.015-.229 14.306-4.539 14.306-11.555 0-17.561-29.254-21.367-29.254-3.76v111.925"
    />
  </svg>
);
export default SvgOlympicRings;
