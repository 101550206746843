import * as React from "react";
import type { SVGProps } from "react";
const SvgMegaphone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M142.573 288.672c-6.531-10.206-20.216-13.209-30.422-6.653l-56.18 35.964c-10.206 6.531-13.221 20.216-6.69 30.422l73.955 115.498c6.531 10.17 20.216 13.185 30.422 6.654l56.217-36.001c10.169-6.519 13.185-20.18 6.654-30.385zm0 0c66.069-57.511 120.919-128.402 165.283-211.794m-91.254 207.228 31.911 49.845m57.511-137.424 54.349 84.82m-92.535-29.567 31.912 49.845m-83.209 102.546c79.888-35.928 162.914-62.834 257.219-68.255m52.152-219.533-49.527 31.752M447.233 27.217 431.546 83.91m84.637 128.268L575 210.566M315.119 43.661c11.158-7.13 26.076-3.87 33.206 7.288l165.892 259.038c7.129 11.121 3.87 26.076-7.252 33.205-11.158 7.13-26.075 3.87-33.205-7.288L307.868 76.878c-7.13-11.121-3.87-26.076 7.251-33.205zM152.755 471.155l57.133-36.599 62.968 98.383c2.124 3.296 1.172 7.764-2.161 9.888l-45.059 28.835c-3.296 2.124-7.764 1.135-9.889-2.161l-62.992-98.358zM59.206 363.836l-25.893 16.602c-8.362 5.36-10.84 16.579-5.481 24.941l34.695 54.191c5.359 8.362 16.578 10.804 24.94 5.444l25.893-16.578z"
    />
  </svg>
);
export default SvgMegaphone;
