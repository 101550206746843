import * as React from "react";
import type { SVGProps } from "react";
const SvgSnowGlobe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M76.502 524.594a23.785 23.785 0 0 0 22.046-14.857l47.596-117.535a14.513 14.513 0 0 0-2.201-14.615l-1.967-2.414a10.621 10.621 0 0 1-.117-13.271c3.973-5.057 11.604-5.545 16.973-2.005 20.543 13.545 65.771 30.888 137.523 25.873 73.732-5.153 203.358-33.425 192.869-200.453-10.793-75.688-79.499-191.708-237.356-151.088-3.581.921-7.098 2.347-10.343 4.12-27.366 14.954-90.015 51.585-86.938 146.968.638 19.794-.964 56.408-10.685 70.135-3.435 4.851-10.298 3.643-14.046-.97-14.153-17.421-25.123-62.355 12.552-145.72 6.233-12.307-.498-22.173-8.617-11.02-24.087 33.085-40.86 80.327-34.88 144.991 2.619 24.291 17.421 77.629 55.676 96.657m368.911 199.122h-29.181a10.497 10.497 0 0 1-9.893-6.987c-4.332-12.203-22.601-7.278-20.212 5.45l.27 1.439c.905 4.82-1.549 9.632-6.11 11.435-51.715 20.44-172.122 44.442-310.042 4.164-12.849-3.753-19.473-17.737-14.809-30.285l39.461-106.179c4.575-12.31 18.286-18.6 31.034-15.444 47.307 11.712 132.535 20.146 223.406-8.147 7.022-2.186 11.413-9.08 10.688-16.398-.808-8.155-7.687-14.356-15.882-14.318l-25.978.122"
    />
  </svg>
);
export default SvgSnowGlobe;
