import * as React from "react";
import type { SVGProps } from "react";
const SvgDocumentWithChart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M507.502 136.225H403.587V25M150.696 225.52v283.598h310.589M204.91 448.952v60.166m54.512-141.807v141.807m54.526-182.183v182.182m54.526-149.538v149.539m54.512-244.067v244.066M462.643 575H137.357c-24.772 0-44.859-20.086-44.859-44.858V69.858C92.498 45.086 112.585 25 137.357 25h262.622l107.507 107.507v397.619c0 24.772-20.087 44.858-44.859 44.858z"
    />
  </svg>
);
export default SvgDocumentWithChart;
