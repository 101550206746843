import * as React from "react";
import type { SVGProps } from "react";
const SvgAcquiaLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M25 339.998h24.3L94.2 242.087c2.63-5.791 10.879-5.791 13.465 0l44.363 97.911h-18.414l-5.93-13.052H74.224l-5.93 13.676s72.988 42.006 151.459-5.88a126.743 126.743 0 0 1-7.535-15.057c-20.777 17.373-47.484 1.604-47.484-21.337 0-12.428 14.58-41.249 46.905-20.491l8.426-14.878s-12.038-9.533-30.586-9.533c-18.547 0-41.598 19.377-41.598 45.169 0 42.408 44.274 55.281 85.159 35.28 10.076-4.944 9.408-8.864 9.408-8.953 0-1.515-.803-3.163-2.943-3.876-16.809-5.657 10.567 41.383 58.363 10.958l8.159 7.84h25.013l-22.204-20.758c14.134-34.077-9.051-65.126-39.057-65.126 1.07 17.017 26.93 23.342 26.662 45.615 0 12.963-9.719 27.396-27.108 27.396-17.389 0-30.096-12.072-30.096-27.529 0-24.545 27.867-28.688 28.892-45.482-24.745 0-44.408 18.531-44.408 46.061 0 63.254 85.249 82.454 133.535 38.086 7.625-6.949 10.968-1.47 10.656.802a3.832 3.832 0 0 1-4.235 3.296c-11.816-1.024-37.854-9.132-37.854-37.195v-48.867h16.274s.357 34.478.357 49.49 14.713 20.536 23.809 20.536c9.095 0 21.49-7.751 21.49-21.159 0-11.092.669-49.535.669-49.535h16.318s.313 30.469.313 43.209c0 25.436-12.841 35.904-26.886 41.695a9.899 9.899 0 0 0-4.726 4.009c-2.051 3.475-.624 6.103 1.649 7.974 3.255 2.672 7.848 3.163 11.727 1.514l33.841-15.279v-84.236l17.031-.178v84.815l-6.643-.089c-3.388-.045-3.165-2.806-2.764-4.054.401-1.247 2.363-2.806 3.567-2.494 9.006 2.984 57.337 26.727 86.363-3.564l-.179 10.602h16.631l.178-83.924h-16.318v12.072c-32.236-31.538-69.108-5.568-69.108 30.959 0 25.125 7.699 52.853 125.584 58.622m-455.075-47.085-19.395-42.674-19.35 42.986c0 .267 38.745-.312 38.745-.312M492.842 267.7c-14.579 0-26.395 13.453-26.395 30.024s11.816 30.024 26.395 30.024c14.58 0 26.395-13.408 26.395-30.024 0-16.615-11.815-30.024-26.395-30.024"
    />
  </svg>
);
export default SvgAcquiaLogo;
