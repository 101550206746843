import * as React from "react";
import type { SVGProps } from "react";
const SvgHeadWithPigtails = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M447.613 287.108c-96.617 43.311-176.946-41.09-232.473-67.743-30.355 99.579-82.181 76.997-82.181 76.997S73.731 63.888 298.06 70.182c224.329 6.293 184.35 198.787 149.553 216.926m0 0c11.475 8.144 35.909 25.114 35.909 55.524-3.702 24.062-17.399 42.571-31.836 44.422-31.095 84.031-83.66 142.89-151.033 142.89-67.373 0-116.977-58.859-150.294-143.261h-.741c-16.288 2.962-33.316-18.138-37.388-44.051-4.072-25.912 4.442-38.129 18.879-42.941m216.187 138.825s-14.437 19.991-46.273 19.991c-31.835 0-46.272-19.991-46.272-19.991M168.069 103.44s-69.965 16.289-79.96 78.85c-9.995 62.56-6.293 83.661-11.105 101.8-1.851 6.663-11.475 24.43-21.1 28.132a54.783 54.783 0 0 0-25.912 24.062s-11.475-105.872.37-173.985c11.846-68.113 81.069-87.733 137.707-58.859m264.13 0s69.964 16.289 79.959 78.85c9.995 62.56 5.923 83.661 10.735 101.8 1.851 6.663 11.846 24.43 21.101 28.132a54.415 54.415 0 0 1 26.283 24.062s11.105-105.872-.741-173.985c-11.846-68.113-80.7-87.733-137.337-58.859"
    />
  </svg>
);
export default SvgHeadWithPigtails;
