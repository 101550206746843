import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckedBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="m25 319.312 156.36 156.359L575 82.021M65.47 223.662h294.317v294.317H65.471z"
    />
  </svg>
);
export default SvgCheckedBox;
