import * as React from "react";
import type { SVGProps } from "react";
const SvgHub = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M355.745 385.054H253.642l51.06-73.254-51.06-73.27h102.103m78.049-125.641-53.511 92.7m0 212.437 49.513 71.771M174.374 444.61l40.829-38.423M433.794 311.8c0 72.374-57.796 131.045-129.092 131.045-71.296 0-129.094-58.671-129.094-131.045 0-72.375 57.798-131.046 129.094-131.046 71.296 0 129.092 58.671 129.092 131.046m70.708-239.442c0 26.154-20.888 47.357-46.654 47.357-25.767 0-46.655-21.203-46.655-47.357 0-26.155 20.888-47.358 46.655-47.358 25.766 0 46.654 21.203 46.654 47.358m0 455.285c0 26.154-20.888 47.357-46.654 47.357-25.767 0-46.655-21.203-46.655-47.357 0-26.155 20.888-47.358 46.655-47.358 25.766 0 46.654 21.203 46.654 47.358m-315.695-48.795c0 26.155-20.888 47.357-46.655 47.357-25.766 0-46.655-21.202-46.655-47.357 0-26.155 20.889-47.358 46.655-47.358 25.767 0 46.655 21.203 46.655 47.358"
    />
  </svg>
);
export default SvgHub;
