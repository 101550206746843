import * as React from "react";
import type { SVGProps } from "react";
const SvgLock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M484.383 575H315.135a31.41 31.41 0 0 1-31.337-31.337V387.122a42.333 42.333 0 0 1-25.3-47.625 42.329 42.329 0 0 1 42.004-33.822c22.556.357 41.116 18.702 41.615 41.259a42.328 42.328 0 0 1-26.839 40.402v75.451H137.464a21.844 21.844 0 0 1-21.842-21.843V270.341a25.55 25.55 0 0 1 15.824-23.63 25.549 25.549 0 0 1 9.802-1.925H418.71a10.35 10.35 0 0 1 10.279 10.35 10.346 10.346 0 0 1-10.279 10.279h-14.205a12.991 12.991 0 0 1-12.991-12.92v-79.591c0-50.61-41.33-91.798-92.012-91.512-50.681.285-90.941 42.044-90.941 92.511v42.401h-56.392v-43.4C152.169 91.243 218.341 25 300.073 25c81.661 0 147.833 66.243 147.833 147.904v71.882h10.85a25.553 25.553 0 0 1 25.626 25.555v201.44a25.55 25.55 0 0 1-15.825 23.63 25.521 25.521 0 0 1-9.801 1.925H115.617"
    />
  </svg>
);
export default SvgLock;
