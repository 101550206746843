import * as React from "react";
import type { SVGProps } from "react";
const SvgOne = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M276.283 503.559V165.258c0-17.345 23.318-23.689 31.52-8.432 3.859 7.147 2.894 17.104-6.674 25.535l-94.721 94.593-49.049-49.063 131.628-131.45h73.654v407.118z"
    />
  </svg>
);
export default SvgOne;
