import * as React from "react";
import type { SVGProps } from "react";
const SvgSedan = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M320.712 217.078v44.884m200.696 26.328c-.778-18.036-15.71-32.185-33.752-32.03l-29.811.259s-49.513-50.481-134.851-48.46c-85.39 2.021-123.756 56.027-123.756 56.027s-26.753 3.525-70.2 8.086c-28.308 2.954-42.254 21.146-48.527 33.429C77.504 311.51 76 318.04 76 324.622v16.689c0 11.455 9.28 20.68 20.686 20.68h22.346c-1.452-4.665-2.229-9.692-2.229-14.823 0-27.677 22.449-50.067 50.083-50.067 27.634 0 50.083 22.442 50.083 50.067s-.778 10.158-2.23 14.823h166.322a49.87 49.87 0 0 1-2.074-14.253c0-27.988 22.657-50.637 50.654-50.637 27.996 0 50.653 22.649 50.653 50.637s-.726 9.744-2.074 14.253h12.962c28.307.363 32.818-14.823 32.818-14.823l-2.592-58.93zm-303.506-23.686 216.716-5.545s-13.636-44.21-111.106-41.93c-85.857 2.021-105.558 47.475-105.558 47.475zm254.356 83.963c0 23.988-19.452 43.433-43.447 43.433s-43.447-19.445-43.447-43.433c0-23.987 19.452-43.432 43.447-43.432s43.447 19.445 43.447 43.432m-18.509 0c0 13.768-11.165 24.93-24.938 24.93s-24.938-11.162-24.938-24.93c0-13.768 11.165-24.93 24.938-24.93s24.938 11.162 24.938 24.93m-244.816 0c0 23.988-19.452 43.433-43.447 43.433s-43.447-19.445-43.447-43.433c0-23.987 19.452-43.432 43.447-43.432s43.447 19.445 43.447 43.432m-18.509 0c0 13.768-11.165 24.93-24.938 24.93s-24.938-11.162-24.938-24.93c0-13.768 11.165-24.93 24.938-24.93s24.938 11.162 24.938 24.93"
    />
  </svg>
);
export default SvgSedan;
