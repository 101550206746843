import * as React from "react";
import type { SVGProps } from "react";
const SvgEight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M195.039 293.27a4.431 4.431 0 0 0 4.19-6.506 4.432 4.432 0 0 0-2.29-2.045c-36.412-16.31-62.375-43.229-62.375-84.242 0-73.95 85.647-104.036 165.436-104.036s165.594 30.086 165.594 104.036c0 49.089-37.994 78.067-85.646 92.793 51.451 16.469 92.612 53.206 92.612 101.028 0 73.316-82.322 109.261-172.56 109.261-122.059 0-172.56-57.481-172.56-104.194m207.547-60.332a101.307 101.307 0 0 0-70.291 0c-22.797 8.551-44.485 23.278-44.485 45.289 0 28.819 34.67 47.188 79.789 47.188s79.948-18.369 79.948-47.188c0-22.17-22.164-36.738-44.961-45.289M300 168.49c-42.428 0-72.824 17.26-72.824 44.972 0 16.31 13.14 27.869 29.446 35.628a100.66 100.66 0 0 0 86.914-.316c15.831-7.601 28.654-19.161 28.654-35.312 0-27.712-30.237-44.972-72.19-44.972"
    />
  </svg>
);
export default SvgEight;
