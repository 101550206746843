import * as React from "react";
import type { SVGProps } from "react";
const SvgScatterPlotGraph = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M420.61 165.968c3.389-8.325 3.467-18.259-1.156-28.381l-.016.016a28.693 28.693 0 0 0-13.417-13.793c-25.382-12.433-50.452 5.67-50.452 29.616 0 10.34 4.686 19.587 12.059 25.757m52.982-13.215c-4.983 12.356-17.12 21.087-31.302 21.087a33.66 33.66 0 0 1-21.68-7.872m52.982-13.215c26.319 10.512 63.681 25.476 89.845 36.051m-142.827-22.836-94.314 112.791m-186.5 135.938c-6.076-9.044-16.385-14.995-28.084-14.995-23.898 0-42.002 24.82-29.834 50.03 2.812 5.826 7.591 10.59 13.433 13.402 25.273 12.137 50.14-5.92 50.14-29.771a33.552 33.552 0 0 0-5.655-18.666m0 0 137.111-91.172m0 0c8.591 12.84 25.788 19.415 43.36 11.606 6.748-2.999 12.152-8.498 15.136-15.245 7.169-16.229 1.952-32.099-9.107-41.127m-49.389 44.766c-6.217-9.262-7.935-21.836-2.062-34.442 3.124-6.669 8.684-11.964 15.479-14.838 13.558-5.733 26.757-2.984 35.972 4.514M58.73 119.765v82.254m0 58.637v82.269m512.86-142.531c13.465 29.927-15.291 58.808-45.266 45.766-6.669-2.905-12.058-8.185-15.151-14.761-4.904-10.387-4.545-20.821-.703-29.381 7.076-15.854 26.117-25.241 45.657-16.775 6.841 2.967 12.418 8.356 15.479 15.135z"
    />
  </svg>
);
export default SvgScatterPlotGraph;
