import * as React from "react";
import type { SVGProps } from "react";
const SvgFour = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M325.22 320.144h-99.821l99.821-153.881z"
    />
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M325.22 389.512v114.047h78.615V389.512h47.715v-69.368h-47.715V96.441H295.228L148.45 327.414v62.098z"
    />
  </svg>
);
export default SvgFour;
