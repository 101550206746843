import * as React from "react";
import type { SVGProps } from "react";
const SvgLaptop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M25 369.561h130.064v-9.616a15.81 15.81 0 0 1 4.642-11.188 15.854 15.854 0 0 1 11.205-4.635h325.587V153.15H132.635v192.676H109.84V129.537h409.453v238.198h-65.337l49.49 75.221H125.687l34.375-56.963h359.231L575 470.464H54.011l52.294-79.237z"
    />
  </svg>
);
export default SvgLaptop;
