import * as React from "react";
import type { SVGProps } from "react";
const SvgOverlappingClouds = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M125.963 383.009H26.212c-9.498-103.362 37.096-150.223 130.505-118.857-5.354-147.616 196.817-166.879 211.684-58.07 63.722-22.559 102.215 4.553 101.572 70.441 69.75-7.504 100.551 17.598 92.639 87.898"
    />
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m571.89 463.229-425.801-1.837c-1.664-82.639 25.354-115.198 101.964-92.859-.643-107.145 153.269-138.966 170.145-43.517 48.698-18.179 75.339 0 78.573 53.894 68.353 4.333 86.375 27.269 75.119 84.303z"
    />
  </svg>
);
export default SvgOverlappingClouds;
