import * as React from "react";
import type { SVGProps } from "react";
const SvgWiFi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M25 180.764c44.198-44.198 140.836-118.86 286.416-118.86a403.029 403.029 0 0 1 245.214 82.653c38.205 28.716 11.237 73.664-29.965 42.7a353.087 353.087 0 0 0-215.249-72.915c-120.61 0-236.225 70.168-236.225 104.628 0 9.489 6.743 23.223 18.23 34.709 69.668 69.669 69.668-34.459 217.995-34.459 110.371 0 146.828 61.178 177.293 61.178 22.723 0 32.462-25.97 12.485-45.946-1.997-1.998-74.912-67.671-189.778-67.671-82.904 0-200.017 43.699-200.017 103.629 0 22.224 8.49 47.944 26.22 65.923 57.183 56.933 106.625-12.236 173.797-12.236a143.331 143.331 0 0 1 77.659 22.724c40.453 23.223 61.678-11.986 36.208-37.457-11.486-11.486-56.185-37.706-113.867-37.706a195.277 195.277 0 0 0-104.129 29.965c-20.725 13.485-23.972 27.968-23.972 48.444 0 135.092 138.339 121.109 158.316 101.132a42.722 42.722 0 0 0 10.653-17.797 42.709 42.709 0 0 0-7.862-39.466 42.699 42.699 0 0 0-75.706 27.048v117.114z"
    />
  </svg>
);
export default SvgWiFi;
