import * as React from "react";
import type { SVGProps } from "react";
const SvgTools = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m281.436 259.076 130.892-151.967 4.684-32.844L473.48 25l31.746 27.37-34.609 63.602-30.706 10.947-100.965 136.588 101.225 81.067c73.122-35.711 149.367 35.45 131.672 105.308l-66.096-52.654-52.565 65.687 66.096 52.914c-88.475 33.626-145.203-44.052-131.671-105.308l-49.052-41.706m-58.68-152.749-67.917-54.218C224.709 72.441 144.301 28.388 80.286 56.28l61.932 49.786-52.564 65.948-61.933-49.786c-14.572 64.123 46.58 137.63 131.672 105.308l130.111 99.573-22.9 30.237 42.156 34.147-32.788 41.706-31.487-25.284L112.553 575l-65.835-52.915L178.91 358.128l-32.071-26.268 32.071-42.759 37.518 31.021"
    />
  </svg>
);
export default SvgTools;
