import * as React from "react";
import type { SVGProps } from "react";
const SvgTwo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M154.152 503.559h300.842v-74.397c-23.3 0-131.856.87-201.323.87-18.837 0-18.837 20.883 0 20.883l39.197-24.799c26.785-17.402 159.078-108.332 159.078-201.22 0-103.221-105.616-128.455-153.851-128.455s-102.568 15.336-153.089 65.804c22.212 27.519 48.235 55.798 48.235 55.798s40.504-45.9 106.378-45.9 65.874 48.946 65.874 48.946c-1.416 58.843-57.599 103.003-211.341 214.925z"
    />
  </svg>
);
export default SvgTwo;
