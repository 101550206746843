import * as React from "react";
import type { SVGProps } from "react";
const SvgTeddyBear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M281.563 476.255h-51.661c-1.845 8.171-5.272 15.287-9.225 21.613-15.288 22.931-45.072 32.947-71.166 23.722-38.218-13.442-46.389-61.677-37.164-85.662 16.342-41.381 58.25-32.683 58.25-32.683v-28.73s-58.513 18.977-64.839-25.831c-9.489-67.211 106.221-95.941 106.221-95.941s-42.963-40.591-12.652-99.895c0 0-44.281-41.645-9.225-69.848 27.675-22.14 58.25 8.698 58.25 8.698 11.07-3.953 32.684-3.69 32.684-3.69m.527 0s21.613-.263 32.683 3.69c0 0 30.575-30.838 58.25-8.698 35.319 28.203-9.225 69.848-9.225 69.848 30.311 59.568-12.651 99.895-12.651 99.895s115.709 28.73 106.221 95.941c-6.326 44.808-64.84 25.831-64.84 25.831v28.73s41.908-8.698 58.25 32.683c10.543 26.621-.527 85.398-51.133 88.825-66.422 4.481-67.476-69.057-20.559-72.22 41.117-2.899 68.529 33.474 116.236 61.677M249.67 212.152c8.171 7.117 15.815 7.907 21.35 6.853 6.062-1.318 10.543-6.853 10.543-13.179 0-5.535-3.163-10.279-8.171-12.651l-3.69-1.582c-2.372-1.054-3.954-3.426-3.954-5.798 0-3.427 2.9-6.326 6.326-6.326h9.489m0-.264h9.488c3.427 0 6.326 2.9 6.326 6.326 0 2.636-1.581 5.008-3.953 5.799l-3.69 1.581c-5.008 2.109-8.171 7.117-8.171 12.652 0 6.326 4.217 11.861 10.543 13.179 5.535 1.317 12.915.527 21.349-6.853m-31.892 65.63c-35.056 0-63.522 33.738-63.522 75.383 0 41.645 28.466 75.382 63.522 75.382 35.055 0 63.521-33.737 63.521-75.382s-28.466-75.383-63.521-75.383m-30.841-111.756c-5.799 0-10.543-5.007-10.543-10.806 0-5.799 4.744-10.543 10.543-10.543 6.062 0 10.807 4.744 10.807 10.543 0 6.062-4.745 10.806-10.807 10.806m60.36-.527c-5.799 0-10.543-5.008-10.543-10.807 0-5.798 4.744-10.543 10.543-10.543 6.062 0 10.806 4.745 10.806 10.543-.263 6.062-5.008 10.807-10.806 10.807"
    />
  </svg>
);
export default SvgTeddyBear;
