import * as React from "react";
import type { SVGProps } from "react";
const SvgSpeechBubbleEllipsis = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M556.147 277.512c-48.225 9.895-116.949 35.337-144.365 74.782-15.624 22.478 14.358 38.13 28.413 14.639 18.31-30.606 21.562-71.086 21.562-109.351 0-113.924-92.395-206.252-206.32-206.252-113.924 0-206.252 92.328-206.252 206.252a205.576 205.576 0 0 0 56.085 141.393L43.853 548.67l150.91-100.899c4.252-2.834 14.511-13.025 3.105-22.947-16.536-14.375-38.538 8.842-21.125 22.61 3.239 2.565 9.651 4.522 11.811 5.332a207.192 207.192 0 0 0 66.883 11.068m-79.735-206.43c0 15.056-12.205 27.26-27.26 27.26-15.055 0-27.26-12.204-27.26-27.26 0-15.055 12.205-27.26 27.26-27.26 15.055 0 27.26 12.205 27.26 27.26m109.04 0c0 15.056-12.205 27.26-27.26 27.26-15.055 0-27.26-12.204-27.26-27.26 0-15.055 12.205-27.26 27.26-27.26 15.055 0 27.26 12.205 27.26 27.26m109.04 0c0 15.056-12.204 27.26-27.26 27.26-15.055 0-27.26-12.204-27.26-27.26 0-15.055 12.205-27.26 27.26-27.26 15.056 0 27.26 12.205 27.26 27.26"
    />
  </svg>
);
export default SvgSpeechBubbleEllipsis;
