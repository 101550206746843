import * as React from "react";
import type { SVGProps } from "react";
const SvgBinoculars = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m225.466 346.587-48.69-.09c-5.27 0-10.584.407-15.921 1.289-24.673 4.093-45.569 17.278-59.839 35.55l-17.46-.18-1.695-.046c-4.048-.068-8.187.362-12.348 1.244-30.191 6.468-49.459 36.184-42.991 66.42 5.586 26.075 28.472 43.964 54.072 44.235h1.063l14.926.158h-.023c17.414 26.279 47.175 42.901 79.673 42.924l296.957.723c2.488 0 4.975-.045 7.463-.248 99.234-8.164 95.819-97.154 93.038-112.668-7.893-44.032-44.687-75.602-87.339-78.7-62.463-4.026-112.329 50.906-101.293 112.487 1.923 10.696 5.541 20.647 10.539 29.648-9.521.09-36.184-.633-42.991-.633a133.2 133.2 0 0 1-6.174-22.842c-5.676-31.728.249-62.847 14.745-89.012 8.413-11.353 18.205-21.982 35.053-29.987l-67.505-.114-62.96-.407-.204 102.65 32.882.429-.09-283.614c.181-16.011 2.284-27.816 8.005-38.717 8.775-16.78 26.663-26.776 45.592-26.73l126.124.203h1.922c47.378.362 89.013 35.958 94.44 85.598 5.337 48.599-27.839 94.1-75.715 104.051-53.054 11.014-104.39-23.927-113.866-76.913-4.681-26.143 1.719-51.698 15.83-71.871-11.058-.52-34.329-.995-44.755-.972-10.764 23.926-14.699 51.223-9.724 78.994 5.156 28.721 19.064 53.665 38.445 72.616l-45.388-.067m-77.14-230.81-.203 38.627-85.394.294c-5.27 0-10.584.407-15.921 1.289-24.673 4.093-45.592 17.278-59.862 35.551l-17.459-.181-1.696-.046c-4.048-.067-8.187.362-12.348 1.244-30.19 6.468-49.459 36.184-42.99 66.42 5.585 26.076 28.471 43.964 54.072 44.235h1.063l14.925.159h-.022c17.413 26.278 47.175 42.9 79.672 42.923l52.309.135.972-120.288-48.328-1.244-4.071 270.814m303.764-292.932c1.583 21.077 33.787 28.495 33.877 56.153.227 16.101-11.692 34.171-33.153 34.465-21.462.294-37.564-14.451-37.835-33.629-.43-30.485 34.103-36.116 35.031-56.967h.927l1.176-.022zm0 245.418c1.583 21.077 33.787 28.495 33.877 56.153.227 16.102-11.692 34.172-33.153 34.466-21.462.293-37.564-14.451-37.835-33.629-.43-30.485 34.103-36.116 35.031-56.967h.927l1.176-.023z"
    />
  </svg>
);
export default SvgBinoculars;
