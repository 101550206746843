import * as React from "react";
import type { SVGProps } from "react";
const SvgBird2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M225.208 139.203c0 11.865 9.619 21.484 21.484 21.484 11.866 0 21.485-9.619 21.485-21.484 0-11.865-9.619-21.484-21.485-21.484-11.865 0-21.484 9.619-21.484 21.484m0 0-57.915-.001c-37.957-.035-52.344 30.737-32.139 68.694l104.038 195.512m197.003-264.206h-74.406c-37.957 0-68.728 30.772-68.728 68.729v195.477l35.941-.001c23.429 0 46.473 6.063 66.853 17.611l6.396 3.628h55.217l37.589-21.221h68.781M393.244 227.732H318.75m-109.733 0H176.51m188.258 88.548H318.75m245.123 166.001h-68.781l-37.588-21.221h-55.218a163.67 163.67 0 0 1-80.609 21.221h-98.203c-78.927 0-76.088-19.153-100.253-35.012L25 421.912c47.787-8.429 109.769-18.505 214.21-18.505m280.66 39.428H575M251.967 227.732c0 11.865-9.619 21.484-21.484 21.484-11.865 0-21.484-9.619-21.484-21.484 0-11.865 9.619-21.484 21.484-21.484 11.865 0 21.484 9.619 21.484 21.484m227.003-88.529c0 11.865-9.619 21.484-21.484 21.484-11.866 0-21.484-9.619-21.484-21.484 0-11.865 9.618-21.484 21.484-21.484 11.865 0 21.484 9.619 21.484 21.484m-42.776 88.529c0 11.865-9.618 21.484-21.484 21.484-11.865 0-21.484-9.619-21.484-21.484 0-11.865 9.619-21.484 21.484-21.484 11.866 0 21.484 9.619 21.484 21.484m-28.598 88.548c0 11.865-9.619 21.484-21.485 21.484-11.865 0-21.484-9.619-21.484-21.484 0-11.865 9.619-21.484 21.484-21.484 11.866 0 21.485 9.619 21.485 21.484"
    />
  </svg>
);
export default SvgBird2;
