import * as React from "react";
import type { SVGProps } from "react";
const SvgSmartWatch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M384.855 168.1h-169.71L236.295 25h127.41zM400.071 168.1H199.929c-12.384 0-22.422 10.041-22.422 22.427v218.946c0 12.386 10.038 22.428 22.422 22.428h200.142c12.384 0 22.422-10.042 22.422-22.428V190.527c0-12.386-10.038-22.427-22.422-22.427"
    />
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M394.237 196.364H205.764v207.273h188.473zM384.855 431.9h-169.71l21.15 143.1h127.41z"
    />
  </svg>
);
export default SvgSmartWatch;
