import * as React from "react";
import type { SVGProps } from "react";
const SvgScreenWithChart2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M371.866 501.704c-17.634-8.637-29.271-23.398-29.271-40.174 0-16.744 11.621-31.536 29.271-40.173m-143.748 0c17.635 8.637 29.271 23.414 29.271 40.173 0 16.76-11.621 31.537-29.271 40.174m-50.124.001h240.137M64.799 98.295H535.2c21.98 0 39.799 17.819 39.799 39.799v243.464c0 21.98-17.819 39.799-39.799 39.799H64.799c-21.98 0-39.799-17.819-39.799-39.799V138.094c0-21.98 17.819-39.8 39.799-39.8m455.751 96.78v176.58H79.013v-55.559l65.415-37.862 48.202 29.974 72.304-105.401 77.661 51.404 103.902-82.331z"
    />
  </svg>
);
export default SvgScreenWithChart2;
