import * as React from "react";
import type { SVGProps } from "react";
const SvgTable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M226.95 263.35h146.1l26.9 141.1h26.3l-27.3-185.6h-197.9l-27.3 185.6h26.3zM426.15 195.549h-252.4v23.3h252.4z"
    />
  </svg>
);
export default SvgTable;
