import * as React from "react";
import type { SVGProps } from "react";
const SvgLineGraph2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M28.603 90.526v47.632m0 39.445v47.632m0 39.446v47.632m137.136-70.194c-65.34 5.145-121.247 113.425-137.136 161.695v110.19H165.74m0-271.885v271.885m0-271.885c68.569-5.399 99.473 49.375 136.701 77.642M165.739 514.004H302.44m0-194.243c13.6 10.326 28.044 17.115 45.476 16.141 23.268-5.178 36.481-39.512 47.926-75.089 9.323-28.982 21.284-63.144 42.755-79.474M302.44 319.761v194.243m0 0h136.157m0-332.665c4.886-3.716 10.264-6.509 16.216-8.107 62.927-16.898 123.79-45.236 123.79-82.706v423.478H438.597m0-332.665v332.665M122.712 138.158v43.181h115.343v-43.181z"
    />
  </svg>
);
export default SvgLineGraph2;
