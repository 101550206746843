import * as React from "react";
import type { SVGProps } from "react";
const SvgPersonFromBehind = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m173.045 166.447 45.44.168v-31.108m-45.44 30.94 84.039 21.987m-84.039-21.987s-4.886 36.646 45.44 36.646m69.707-132.08c10.261.814 43.648 5.862 53.257 10.911m22.801 251.302V305.7s14.169-40.716 19.87-140.065c8.306-144.3-172.15-67.1-177.036-115.472-56.841 68.078 11.401 85.339 11.401 85.339m0 0-.001 135.182c0 19.381 13.193 34.854 35.017 35.016v27.526m-35.016-197.724 26.059 11.401m-3.91 55.214s29.316-30.619 29.316 7.003-7.329 52.769-25.733 35.342m-47.881 119.546c8.143-18.893 24.104-30.782 41.531-30.782h142.182c17.427 0 33.551 11.889 41.531 30.782m-259.935.487h276.71C513.762 364.495 575 425.733 575 501.14v48.697H25V501.14c0-75.407 61.238-136.645 136.645-136.645"
    />
  </svg>
);
export default SvgPersonFromBehind;
