import * as React from "react";
import type { SVGProps } from "react";
const SvgHeadWithShortHair = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M465.8 289.431c0 1.481 22.952 5.182 22.952 6.663 12.586 8.514 19.249 27.764 15.177 52.566-4.072 24.802-18.878 43.681-34.796 45.532-34.427 86.252-58.12 146.221-166.213 146.221-85.511 0-128.451-60.34-165.099-146.592h-.741c-18.139 3.332-36.648-18.878-41.09-45.161-4.442-26.282 4.812-49.974 20.73-55.527l25.913-5.184m205.079 134.747s-14.808 19.99-46.273 19.99c-31.465 0-45.902-19.99-45.902-19.99M125.604 283.709s75.886 24.06 108.832-84.403c30.355 29.985 49.235 60.34 134.007 57.378v-57.378s80.328 8.145 106.611 89.585c0-79.589 38.129-249.502-184.349-224.33-14.067-2.591-69.225-25.173-126.603 53.306-40.35 55.157-38.498 165.842-38.498 165.842"
    />
  </svg>
);
export default SvgHeadWithShortHair;
