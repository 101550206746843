import * as React from "react";
import type { SVGProps } from "react";
const SvgSoccerBall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="m298.968 191.492-.011-86.583m.011 86.583-105.531 75.78 40.179 124.009m65.352-199.789 104.919 75.78m-104.93-162.363 89.51-64.965m-89.51 64.965L209.48 39.911m-130.06 94.94 35.199 104.871-88.778 65.948m167.028-38.398-78.25-27.562M575 305.309l-89.608-64.845m0 0 33.888-105.296m-33.888 105.296-81.505 26.808m-25.173 294.99 35.068-104.913m0 0 110.576.841m-110.576-.841-50.063-66.068M74.811 456.213l110.588.677m0 0 33.615 105.373M185.399 456.89l48.217-65.609m0 0h130.103m0 0 40.168-124.009M574.858 300c0 151.878-123.09 275-274.929 275C148.09 575 25 451.878 25 300S148.09 25 299.929 25c151.839 0 274.929 123.122 274.929 275"
    />
  </svg>
);
export default SvgSoccerBall;
