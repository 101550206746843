import * as React from "react";
import type { SVGProps } from "react";
const SvgPrintedDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M146.865 120.88H43.542v.013c-9.86 0-16.076 8.919-16.076 18.883v357.649c-6.634 108.442 119.399 94.104 119.399 12.955zm0 0V44.72c0-11.91 8.306-19.72 15.253-19.72h395.416c6.947 0 15.253 7.81 15.253 19.72v465.659c0 61.43-49.951 64.473-66.98 64.473H88.609m132.118-303.558h279.438M222.882 344.66h279.059m-143.663 73.379h146.236m-145.322 73.366h146.236M230.312 100.09h262.057c5.262 0 9.572 4.322 9.572 9.585v78.668c0 5.276-4.31 9.585-9.572 9.585H230.312c-5.263 0-9.572-4.322-9.572-9.585v-78.668c0-5.276 4.309-9.585 9.572-9.585m-12.693 317.949h83.944c3.93 0 7.169 3.238 7.169 7.208v58.988c0 3.931-3.239 7.17-7.169 7.17h-83.944c-3.931 0-7.169-3.239-7.169-7.17v-58.988c0-3.97 3.238-7.208 7.169-7.208"
    />
  </svg>
);
export default SvgPrintedDocument;
