import * as React from "react";
import type { SVGProps } from "react";
const SvgScreenWithChart3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M206.225 81.74H25v334.674h250.25v50.325h-45.375c-28.875 0-51.975 23.375-51.975 51.975h244.2c0-28.875-23.375-51.975-51.975-51.975H324.75v-50.325H575V81.739H300.275m-173.249 49.5v230.175c0 10.725-8.525 19.25-19.25 19.25h-7.425c-10.725 0-19.25-8.525-19.25-19.25s8.525-19.25 19.25-19.25h418.825m-17.875-197.175-122.375 70.95-22.275-54.175-79.2 122.1-30.525-44.275-83.325 60.775"
    />
  </svg>
);
export default SvgScreenWithChart3;
