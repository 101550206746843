import * as React from "react";
import type { SVGProps } from "react";
const SvgHappyBubble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m529.941 320.821-40.967 45.961c12.688 0 27.064 8.909 27.064 33.34 0 71.068-88.143 118.852-88.143 118.852s-88.008-51.563-88.008-114.262c0-29.966 15.118-37.93 27.941-37.93 30.236 0 60.134 36.243 60.134 36.243m0 0 10.664 12.283a14.707 14.707 0 0 1 3.507 11.247 14.72 14.72 0 0 1-5.869 10.215l-.135.135a14.787 14.787 0 0 1-16.67.54 14.717 14.717 0 0 1-6.644-10.261 14.692 14.692 0 0 1 .425-6.241 14.697 14.697 0 0 1 2.979-5.5 151.72 151.72 0 0 1 11.743-12.418m0 0c38.605-35.636 60-88.683 60-145.443 0-113.924-92.395-206.251-206.319-206.251-113.925 0-206.252 92.327-206.252 206.251a205.577 205.577 0 0 0 56.085 141.393L70.059 548.669l150.909-100.898c4.252-2.835 14.511-13.026 3.105-22.947-16.535-14.375-38.537 8.841-21.125 22.609 3.24 2.565 9.652 4.522 11.811 5.332a207.203 207.203 0 0 0 66.884 11.069m-44.342-272.933c0 22.475-10.056 40.697-22.542 40.697-12.486 0-22.474-18.222-22.474-40.697 0-22.474 10.056-40.697 22.474-40.697 12.419 0 22.542 18.223 22.542 40.697m111.63-40.697c-12.419 0-22.542 18.223-22.542 40.697 0 22.475 10.123 40.697 22.542 40.697 12.418 0 22.542-18.222 22.542-40.697 0-22.474-10.056-40.697-22.542-40.697M177.099 292.002c14.983 30.776 56.153 52.913 104.544 52.913 48.39 0 89.627-22.137 104.61-52.913z"
    />
  </svg>
);
export default SvgHappyBubble;
