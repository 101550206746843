import * as React from "react";
import type { SVGProps } from "react";
const SvgSmartWatch2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M253.159 268.321h-23.174m118.398 97.4-9.777 69.241h-53.587l-9.777-69.241M253.16 233.2h116.952v132.521H253.16zm95.223 0h-73.141l9.78-67.71h53.587z"
    />
  </svg>
);
export default SvgSmartWatch2;
