import * as React from "react";
import type { SVGProps } from "react";
const SvgKeyboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M508.788 240.302v160.402H91.31V199.749h333.475M160.105 316.34h279.888m-279.888-50.331h41.639m37.658 0h41.639m37.658 0h41.639m37.651 0h42.001"
    />
  </svg>
);
export default SvgKeyboard;
