import * as React from "react";
import type { SVGProps } from "react";
const SvgHeadWithBead = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M321.809 469.342a37.01 37.01 0 0 1-28.873 12.217c-20.36 0-29.244-12.217-29.244-12.217M127.834 269.068c-14.437 5.923-22.581 22.211-18.509 52.196 4.072 29.985 20.361 55.157 36.279 51.455h.004c.369 0 .735 0 .735-.369m0 0 .003-22.952 22.582 18.509s26.652 72.926 53.305 87.363c26.653 14.437 37.019-22.581 41.461-26.283 4.442-3.702 7.774-10.736 33.687-9.625 25.913 1.11 29.984 17.028 36.277 24.432 6.293 7.404 31.465 35.907 53.306-13.327 21.84-49.234 38.129-83.661 38.129-83.661l14.434 26.283m-293.184-.739s-1.107 23.321.003 68.483c1.111 45.162 88.844 111.794 107.723 117.347 18.879 5.552 61.45 10.735 98.098-7.034 36.648-17.768 87.363-102.909 87.363-102.909l-.003-75.148m0 0c14.067-2.221 27.022-23.691 30.724-51.825 3.702-28.134-2.221-39.238-13.327-48.863m-339.825-4.44s2.22-112.535 54.786-142.89c3.701-23.321 36.277-81.44 92.545-87.363 56.267-5.923 112.535 12.587 129.933 28.505 17.399 15.917 68.855 51.454 97.359 32.945-4.812 15.548-.742 54.047-38.5 67.003 12.956 14.437 40.35 75.517 11.476 101.8-28.874 26.283-71.445-78.479-59.229-106.243-.741 1.851-7.033 4.443-8.884 5.553-20.73 12.216-44.792 23.322-68.854 24.803a225.822 225.822 0 0 1-75.517-8.513c-17.398-5.183-34.426-11.847-47.752-24.803a48.847 48.847 0 0 0-1.481 12.586c-.37 4.812-1.11 9.624-1.851 14.436a151.003 151.003 0 0 1-7.774 28.134c-12.586 32.576-40.72 53.677-76.257 54.047"
    />
  </svg>
);
export default SvgHeadWithBead;
