import * as React from "react";
import type { SVGProps } from "react";
const SvgPersonWithHammer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m251.046 462.927-20.872-73.052 77.362 26.997-54.902 96.646c-16.108 28.359 8.848 62.389 40.836 55.356l207.585-45.147 15.427 20.645-42.652 30.855h82.58l-33.576-90.067-183.083 16.788 83.488-67.834c29.493-23.821 37.433-65.565 19.057-98.687L340.432 151.663c-11.344-20.418-39.475-23.14-54.675-5.445-22.687 26.544-25.637 72.825-15.428 110.032l9.529 38.113m-31.762-69.649-70.783-24.275c-2.269-15.427-15.654-27.224-31.762-27.451l-34.257-98.46c1.588-1.816 4.538-4.765 7.714-8.168 5.445 2.95 12.478 2.042 16.788-2.95 5.218-5.671 4.764-14.519-.907-19.737-5.672-5.218-14.52-4.764-19.738.908-1.134 1.134-2.042 2.495-2.496 4.083l-7.713-2.041c-7.033-3.404-15.654-2.042-21.099 4.083a21.853 21.853 0 0 0-3.63 5.672H43.688l8.167 35.391L84.07 75.208c.227.227.454.68.908.907 2.268 2.042 4.99 3.63 7.94 4.31l34.03 97.781c-8.394 5.899-14.066 15.427-14.066 26.544 0 11.116 10.89 28.358 25.183 31.534l115.703 39.702m-23.595 267.479h-67.834l54.675-37.887-47.415-124.324m148.598 2.042-21.779-48.323c-2.722-5.899-.681-12.932 4.764-16.788l30.401-20.872c4.31-2.95 9.755 2.041 7.033 6.579l-19.965 32.669c-8.167 12.931-22.46 20.645-37.66 20.645H114.243v-46.962H87.019c3.63-15.2 15.427-26.317 29.266-26.317 13.839 0 25.636 11.117 29.265 26.317h112.3c7.487 0 14.293-3.857 18.15-9.982l4.084-6.579m-20.191-146.557s-30.627 7.94-44.693-29.72c-20.191 6.125-45.374 12.478-45.374 12.478s-35.164-4.311-1.815-17.469c33.35-13.159 119.787-45.828 119.787-45.828s-18.376-56.036-62.162-37.66c-33.35 14.066-24.729 50.819-24.729 50.819"
    />
  </svg>
);
export default SvgPersonWithHammer;
