import * as React from "react";
import type { SVGProps } from "react";
const SvgCabin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M116.778 545.402h-22.56a2.896 2.896 0 0 1-2.896-2.896v-45.121c0-1.6 1.296-2.897 2.896-2.897h19.664c1.6 0 2.896-1.296 2.896-2.896v-10.955c0-1.6-1.296-2.896-2.896-2.896H94.218a2.897 2.897 0 0 1-2.896-2.897v-43.781a2.896 2.896 0 0 1 2.896-2.896h19.664c1.6 0 2.896-1.297 2.896-2.896v-12.966a2.896 2.896 0 0 0-2.896-2.896H94.218a2.896 2.896 0 0 1-2.896-2.896v-37.752c0-1.6 1.296-2.896 2.896-2.896h19.664c1.6 0 2.896-1.297 2.896-2.896v-8.946c0-1.6-1.296-2.896-2.896-2.896H94.218a2.896 2.896 0 0 1-2.896-2.896v-36.413a2.896 2.896 0 0 1 2.896-2.896h16.753c1.642 0 2.299-2.243.96-3.194-7.167-5.089-15.352-12.589-11.901-16.903 4.093-5.116-18.755 18.255-32.299 32.237a2.887 2.887 0 0 1-3.933.2l-36.134-30.21a2.896 2.896 0 0 1 .005-4.448l88.953-74.019a.435.435 0 0 0 .156-.334V94.984c0-.24.195-.435.435-.435h47.365c.24 0 .434.195.434.435v68.325c0 .366.424.568.708.337L299.385 55.252a.435.435 0 0 1 .555.006L572.558 286.75a2.897 2.897 0 0 1 .134 4.295l-31.189 30.012a2.897 2.897 0 0 1-4.017 0l-33.147-31.896a.435.435 0 0 0-.646.048l-11.541 15.054c-1.46 1.905-.102 4.658 2.299 4.658h6.642a2.897 2.897 0 0 1 2.896 2.896v36.413a2.897 2.897 0 0 1-2.896 2.896h-9.616a2.896 2.896 0 0 0-2.896 2.896v8.946a2.897 2.897 0 0 0 2.896 2.896h9.616a2.896 2.896 0 0 1 2.896 2.896v37.752c0 1.6-1.297 2.896-2.896 2.896h-9.616a2.897 2.897 0 0 0-2.896 2.896v12.966a2.897 2.897 0 0 0 2.896 2.896h9.616a2.897 2.897 0 0 1 2.896 2.896v43.781c0 1.6-1.297 2.897-2.896 2.897h-9.616a2.896 2.896 0 0 0-2.896 2.896v10.955c0 1.6 1.297 2.896 2.896 2.896h9.616a2.897 2.897 0 0 1 2.896 2.897v45.121a2.897 2.897 0 0 1-2.896 2.896h-12.512 50.914"
    />
  </svg>
);
export default SvgCabin;
