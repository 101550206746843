import { useStage } from "@/contexts/StageProvider"
import { useUserInformation } from "@/contexts/UserProvider"
import LoadingSvg from "@/assets/loading.svg"
import classNames from "classnames"
import { signIn } from "next-auth/react"
import { useState } from "react"

export const LoginButton = () => {
  const { isLoggedIn } = useUserInformation()
  const { stage } = useStage()
  const [clicked, setClicked] = useState(false)
  const className = 'border w-fit whitespace-nowrap border-navy-600 rounded p-3 transition-all'
  const darkMode = 'bg-navy-600 text-white hover:bg-white hover:text-navy-600'
  
  if (['production', 'preprod'].includes(stage) || isLoggedIn) {
    return <a className={classNames(className, 'hover:bg-navy-600 hover:text-white')} href="https://www.acquia.com/" target="_blank">Acquia.com</a>
  }

  function onClick() {
    setClicked(true)
    signIn("okta")
  }

  return <>
  <button disabled={clicked} className={classNames(className, clicked ? 'opacity-50' : darkMode, 'flex gap-1')} onClick={onClick}>{clicked ? <><LoadingSvg className="w-6" /> Please wait</> : 'Login'}</button>
  </>
}