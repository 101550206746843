import * as React from "react";
import type { SVGProps } from "react";
const SvgBattery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M440.244 564.024V87.195c0-6.061-4.914-10.975-10.976-10.975H170.732c-6.062 0-10.976 4.913-10.976 10.975v476.829c0 6.062 4.914 10.976 10.976 10.976h258.536c6.062 0 10.976-4.914 10.976-10.976"
    />
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M410.242 534.268V116.706c0-6.061-4.914-10.975-10.976-10.975H196.584c-6.062 0-10.976 4.914-10.976 10.975v417.562c0 6.061 4.914 10.975 10.976 10.975h202.682c6.062 0 10.976-4.914 10.976-10.975M374.638 34.268V76.22H226.101V34.27A9.265 9.265 0 0 1 235.37 25h130a9.264 9.264 0 0 1 9.268 9.268"
    />
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="m283.02 410.055 61.966-104.224-27.8 7.449 9.556-75.535-73.862 114.482 38.572-24.729z"
    />
  </svg>
);
export default SvgBattery;
