import * as React from "react";
import type { SVGProps } from "react";
const SvgWidenLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M575 343.86h-40.804a26.996 26.996 0 0 0-26.945 26.999v35.57a27 27 0 0 1-26.996 26.999H295.211a23.043 23.043 0 0 1-23.045-22.995v-15.04a27.514 27.514 0 0 1 4.106-14.423l9.033-14.834a7.181 7.181 0 0 0 .205-7.237l-66.618-120.056a2.609 2.609 0 0 1-.307-1.298 2.607 2.607 0 0 1 1.327-2.212c.393-.223.837-.34 1.289-.34h19.965a5.435 5.435 0 0 1 4.722 2.823l80.271 148.698a5.24 5.24 0 0 0 4.453 2.789 5.232 5.232 0 0 0 4.631-2.481l15.398-24.689a7.142 7.142 0 0 0 .154-7.289l-64.669-116.001a2.583 2.583 0 0 1-.344-1.293 2.58 2.58 0 0 1 .353-1.29 2.575 2.575 0 0 1 2.25-1.267h18.168a5.34 5.34 0 0 1 4.671 2.772l50.811 91.158a5.228 5.228 0 0 0 4.497 2.711 5.239 5.239 0 0 0 4.587-2.557l41.675-69.293a6.727 6.727 0 0 0-2.353-9.295 6.729 6.729 0 0 0-3.395-.919H197.182a6.727 6.727 0 0 0-6.73 6.612 6.72 6.72 0 0 0 .827 3.346l68.21 124.779a5.285 5.285 0 0 1-4.619 7.853H127.997c-46.243 0-14.833-91.518 5.132-33.826a175.76 175.76 0 0 0 15.911 33.826c31.205 51.585 87.815 86.026 152.484 86.026 98.747 0 178.71-80.277 178.197-179.136-.513-98.858-79.963-177.287-178.197-177.236-94.026 0-171.012 72.938-177.685 165.277a14.163 14.163 0 0 1-14.114 13.037H25"
    />
  </svg>
);
export default SvgWidenLogo;
