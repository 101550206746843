import * as React from "react";
import type { SVGProps } from "react";
const SvgTablet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M390.824 125.623v49.256L361.9 195.944v-41.338H168.333v290.788h193.726V267.532l-41.002 41.021-28.447 12.829 13.985-27.242 139.534-139.217 14.462 14.413-69.767 69.529v235.513H139.409V125.623z"
    />
  </svg>
);
export default SvgTablet;
