import * as React from "react";
import type { SVGProps } from "react";
const SvgBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M135.795 59.894 98.41 130.125l-70.684 61.212 50.002-91.804zm0 0L156.907 25h286.396l21.112 34.894zm.075 68.946V59.894m328.263 0v70.177M98.413 255.556h-75.21l75.111-125.649H501.69l75.108 125.649h-74.743m-403.64 0V575h403.64V255.556m-403.64 0h403.64M98.414 130.125h403.64l-37.849-70.231H135.798zm473.992 61.212-70.684-61.212-37.385-70.231 58.067 39.639z"
    />
  </svg>
);
export default SvgBox;
