import * as React from "react";
import type { SVGProps } from "react";
const SvgDxpFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M196.296 563.988h43.065V255.607l-27.785-27.785V25h-23.621v36.127h241.709v111.789H239.256v-69.252m37.167 6.215-4.736 22.702h17.06c1.81 0 3.62-.272 5.325-.89 4.54-1.629 12.655-5.928 14.722-16.246.091-.452.166-.905.226-1.342 1.373-9.715-6.546-18.282-16.366-18.282h-13.832m51.377.014 19.262 36.73m-30.319 0 37.816-38.525m29.61 22.838s15.446 2.504 17.83-9.639c.965-4.872-.589-7.723-2.444-9.352-1.584-1.403-3.696-2.066-5.822-2.066h-17.408l-8.205 36.745m-178.16 431.423c0 6.081-4.93 11.011-11.011 11.011-6.082 0-11.012-4.93-11.012-11.011 0-6.082 4.93-11.012 11.012-11.012 6.081 0 11.011 4.93 11.011 11.012"
    />
  </svg>
);
export default SvgDxpFlag;
