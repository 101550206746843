import * as React from "react";
import type { SVGProps } from "react";
const SvgDesktop1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M555.13 535.807H251.931a31.84 31.84 0 0 1-22.554-9.351 31.76 31.76 0 0 1-9.278-22.551 31.748 31.748 0 0 1 9.323-22.468 31.867 31.867 0 0 1 22.509-9.306H416.61v-24.984h-78.938v-80.588h209.347a28.005 28.005 0 0 0 19.785-8.181 27.903 27.903 0 0 0 8.196-19.75V101.22H62.223v228.184h475.682V64.192H52.981a28.007 28.007 0 0 0-19.785 8.181A27.905 27.905 0 0 0 25 92.123v246.505a28.033 28.033 0 0 0 8.233 19.713 28.135 28.135 0 0 0 19.748 8.218h209.347v80.588H99.959"
    />
  </svg>
);
export default SvgDesktop1;
