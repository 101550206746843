import * as React from "react";
import type { SVGProps } from "react";
const SvgMirror = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m167.75 311.502 83.4-66.1m-22.8 103.899c1.6-2.4 121.2-111 121.2-111m-1.5 113.401 64.5-60.6M90.05 176.301h419.9v247.4H90.05zm-21-21h461.9v289.4H69.05zm56 56h349.9v177.4h-349.9z"
    />
  </svg>
);
export default SvgMirror;
