import * as React from "react";
import type { SVGProps } from "react";
const SvgPhoneCall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M172.276 542.717V440.285l75.475-352.548 66.977 14.324a23.03 23.03 0 0 0 3.19 17.343 23.063 23.063 0 0 0 31.879 6.82 23.042 23.042 0 0 0 10.014-14.518l66.976 14.324-82.676 386.233-206.763-44.267 83.684-390.695a27.352 27.352 0 0 1 11.887-17.23 27.379 27.379 0 0 1 20.594-3.788l180.908 38.724a27.374 27.374 0 0 1 17.285 11.859 27.339 27.339 0 0 1 3.816 20.603L370.83 522.7a27.35 27.35 0 0 1-11.887 17.229 27.375 27.375 0 0 1-20.594 3.788c.042 0-106.948-26.562-230.745-50.178m357.929 4.832-20.957-62.981 44.867-48.801-20.957-62.908 44.867-48.873-20.957-62.909 44.939-48.873zM575 171.16l-44.867 48.801 20.957 62.908-44.867 48.873 20.957 62.909-44.939 48.873 20.957 62.909zM62.737 412.142l44.867-48.801-20.957-62.909 44.867-48.873-20.957-62.909 44.939-48.873-20.957-62.908zM25 404.08l44.867-48.801-20.957-62.981 44.939-48.801-20.957-62.908 44.867-48.873-20.957-62.909z"
    />
  </svg>
);
export default SvgPhoneCall;
