import * as React from "react";
import type { SVGProps } from "react";
const SvgMicrophone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M290.433 219.395a12.68 12.68 0 0 0-2.808 13.832 12.68 12.68 0 0 0 18.782 5.711 12.682 12.682 0 0 0 1.86-19.543 12.677 12.677 0 0 0-17.834 0M206.849 575 368.43 413.42a42.127 42.127 0 0 0 12.422-29.866 42.114 42.114 0 0 0-12.422-29.867l-7.736-7.95a42.543 42.543 0 0 0-59.733 0L169.248 477.665a42.328 42.328 0 0 1-59.518 0 41.907 41.907 0 0 1-9.18-13.638 41.91 41.91 0 0 1 0-32.241 41.9 41.9 0 0 1 9.18-13.639l27.503-27.718 24.709 24.925 223.892-210.355-35.883-36.098 27.288-27.288 63.601 63.601a91.54 91.54 0 0 0 34.593-21.702 92.818 92.818 0 0 0 23.769-90.842 92.83 92.83 0 0 0-67.669-65.102 92.82 92.82 0 0 0-109.944 118.987L114.242 367.438z"
    />
  </svg>
);
export default SvgMicrophone;
