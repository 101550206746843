import * as React from "react";
import type { SVGProps } from "react";
const SvgThreeBoxesAndAKeyhole = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="m207.108 192.441-88.62 52.234v108.907m88.62-161.141V84.488m0 107.953 92.886 52.234m94.096-52.234 87.416 52.234v108.907M394.09 192.441l-94.096 52.234m94.096-52.234V84.488M118.488 353.582l88.619 49.67m-88.619-49.67L25 403.252m182.107 107.953 92.887 53.976 94.095-53.976m-186.982 0-88.619 53.976m88.619-53.976V403.252m186.982 107.953V403.252m0 107.953 87.417 53.976m0-211.599L575 403.252m-93.494-49.67-87.417 49.67M207.108 84.488l92.886-49.67 94.096 49.67m-186.982 0 92.886 50.106m0 110.081V134.594m94.096-50.106-94.096 50.106m94.095 268.658 87.417 50.105m0 111.824L575 511.205V403.252m-93.494 161.929V453.357M575 403.252l-93.494 50.105M25 403.252v107.953l93.488 53.976M25 403.252l93.488 50.105m0 111.824V453.357m88.619-50.105-88.619 50.105m209.956-127.645c0-15.711-12.739-28.45-28.449-28.45-15.711 0-28.45 12.739-28.45 28.45 0 11.729 7.097 21.793 17.233 26.15l-3.601 51.076h29.635l-3.601-51.076c10.136-4.351 17.233-14.421 17.233-26.15Z"
    />
  </svg>
);
export default SvgThreeBoxesAndAKeyhole;
