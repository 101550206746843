import * as React from "react";
import type { SVGProps } from "react";
const SvgGroupOfKeyholes = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M25 316.601v70.355l60.927 35.178 60.932-35.178v-70.355l-60.933-35.178zM25 105.255v70.355l60.927 35.178 60.932-35.178v-70.355L85.926 70.078z"
    />
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M104.469 124.539c0-10.243-8.3-18.543-18.542-18.543-10.243 0-18.543 8.3-18.543 18.543 0 7.644 4.624 14.204 11.23 17.044l-2.346 33.286H95.58l-2.346-33.286c6.605-2.84 11.23-9.4 11.23-17.044zM147.324 105.861v70.355l60.933 35.178 60.926-35.178v-70.355l-60.926-35.177z"
    />
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M226.8 125.145c0-10.242-8.301-18.543-18.543-18.543-10.243 0-18.543 8.301-18.543 18.543 0 7.644 4.624 14.205 11.23 17.044l-2.346 33.287h19.312l-2.346-33.287c6.605-2.839 11.23-9.4 11.23-17.044zM391.979 107.073v70.35l60.927 35.178 60.932-35.178v-70.35l-60.932-35.177z"
    />
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M471.448 126.357c0-10.242-8.3-18.543-18.542-18.543-10.243 0-18.543 8.301-18.543 18.543 0 7.644 4.624 14.205 11.23 17.045l-2.346 33.286h19.312l-2.346-33.286c6.605-2.84 11.23-9.401 11.23-17.045zM86.162 211.231v70.355l60.932 35.178 60.927-35.178v-70.355l-60.927-35.177z"
    />
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M165.632 230.515c0-10.243-8.301-18.543-18.543-18.543-10.243 0-18.543 8.3-18.543 18.543 0 7.644 4.624 14.204 11.23 17.044l-2.346 33.286h19.312l-2.346-33.286c6.606-2.84 11.23-9.4 11.23-17.044zM208.487 211.837v70.355l60.932 35.178 60.926-35.178v-70.355l-60.926-35.177z"
    />
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M287.962 231.121c0-10.242-8.301-18.543-18.543-18.543-10.242 0-18.543 8.301-18.543 18.543 0 7.644 4.625 14.205 11.23 17.044l-2.346 33.287h19.312l-2.346-33.287c6.606-2.839 11.23-9.4 11.23-17.044zM330.817 212.443v70.355l60.926 35.178 60.933-35.178v-70.355l-60.933-35.177z"
    />
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M410.286 231.727c0-10.242-8.3-18.543-18.543-18.543-10.242 0-18.542 8.301-18.542 18.543 0 7.644 4.624 14.205 11.23 17.044l-2.346 33.287h19.311l-2.345-33.287c6.605-2.839 11.23-9.4 11.23-17.044zM453.141 213.049v70.35l60.932 35.178L575 283.399v-70.35l-60.927-35.177z"
    />
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M532.611 232.333c0-10.242-8.301-18.543-18.543-18.543-10.243 0-18.543 8.301-18.543 18.543 0 7.644 4.624 14.205 11.23 17.045l-2.346 33.286h19.312l-2.346-33.286c6.606-2.84 11.23-9.401 11.23-17.045zM104.469 335.885c0-10.243-8.3-18.543-18.542-18.543-10.243 0-18.543 8.3-18.543 18.543 0 7.644 4.624 14.204 11.23 17.044l-2.346 33.286H95.58l-2.346-33.286c6.605-2.84 11.23-9.4 11.23-17.044zM147.324 317.207v70.355l60.933 35.178 60.926-35.178v-70.355l-60.926-35.177z"
    />
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M226.8 336.491c0-10.243-8.301-18.543-18.543-18.543-10.243 0-18.543 8.3-18.543 18.543 0 7.644 4.624 14.204 11.23 17.044l-2.346 33.286h19.312l-2.346-33.286c6.605-2.84 11.23-9.4 11.23-17.044zM269.655 317.813v70.355l60.926 35.178 60.932-35.178v-70.355l-60.932-35.177z"
    />
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M349.124 337.097c0-10.242-8.3-18.543-18.543-18.543-10.242 0-18.543 8.301-18.543 18.543 0 7.644 4.625 14.205 11.23 17.044l-2.345 33.287h19.311l-2.346-33.287c6.606-2.839 11.23-9.4 11.23-17.044zM391.979 318.419v70.35l60.927 35.177 60.932-35.177v-70.35l-60.932-35.177z"
    />
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M471.448 337.703c0-10.242-8.3-18.543-18.542-18.543-10.243 0-18.543 8.301-18.543 18.543 0 7.644 4.624 14.205 11.23 17.044l-2.346 33.287h19.312l-2.346-33.287c6.605-2.839 11.23-9.4 11.23-17.044zM208.487 423.183v70.355l60.932 35.178 60.926-35.178v-70.355l-60.926-35.177z"
    />
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M287.962 442.467c0-10.243-8.301-18.543-18.543-18.543-10.242 0-18.543 8.3-18.543 18.543 0 7.644 4.625 14.204 11.23 17.044l-2.346 33.286h19.312l-2.346-33.286c6.606-2.84 11.23-9.4 11.23-17.044zM330.817 423.789v70.355l60.926 35.178 60.933-35.178v-70.355l-60.933-35.177z"
    />
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M410.286 443.073c0-10.242-8.3-18.543-18.543-18.543-10.242 0-18.542 8.301-18.542 18.543 0 7.644 4.624 14.205 11.23 17.044l-2.346 33.287h19.311l-2.345-33.287c6.605-2.839 11.23-9.4 11.23-17.044zM453.141 424.395v70.35l60.932 35.177L575 494.745v-70.35l-60.927-35.177z"
    />
    <path
      stroke="#26A3DD"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M532.611 443.679c0-10.242-8.301-18.543-18.543-18.543-10.243 0-18.543 8.301-18.543 18.543 0 7.644 4.624 14.205 11.23 17.044l-2.346 33.287h19.312l-2.346-33.287c6.606-2.839 11.23-9.4 11.23-17.044z"
    />
  </svg>
);
export default SvgGroupOfKeyholes;
