import * as React from "react";
import type { SVGProps } from "react";
const SvgCircuitChip = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M115.834 281.334H25.132v37.069h90.702v73.614H25.132v37.07h90.702v54.947h55.211V575h37.069v-90.966h73.351V575h37.07v-90.966h73.614V575h37.07v-90.966h54.947v-54.947h90.703v-37.07H380.844c-27.079 0-43.642 12.62-43.642 25.765 0 13.145 18.403 25.239 31.548 13.934a16.82 16.82 0 0 0 6.047-12.356c0-13.146-18.14-27.343-56.262-27.343h-147.49V300h261.591v-92.017c-149.33 0-182.982-4.47-202.175 14.46a18.143 18.143 0 0 0-5.258 12.356 15.786 15.786 0 0 0 4.496 12.139 15.776 15.776 0 0 0 12.067 4.687c22.347 0 27.08-27.079 9.991-35.229-9.991-4.733-26.554-8.413-53.37-8.413H25.132v-37.07h90.702v-54.947h55.211V25h37.069v90.966h73.351V25h37.07v90.966h73.614V25h37.07v90.966h54.947v54.947h90.703v37.07h-90.703v73.351h90.703v37.069h-90.703z"
    />
  </svg>
);
export default SvgCircuitChip;
