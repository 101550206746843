import * as React from "react";
import type { SVGProps } from "react";
const SvgSquareTree = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      fill="#26A3DD"
      d="M297.172 575a3 3 0 1 0 6 0zm6-410.716a3 3 0 0 0-6 0zm-4.967 144.985a3 3 0 1 0 3.936-4.529zm-61.273-61.203a3.001 3.001 0 0 0-3.936 4.529zm61.347-47.53a3 3 0 0 0 3.785 4.656zm51.241-33.924a3 3 0 1 0-3.785-4.655zM242.472 28h115.056v-6H242.472zm115.056 0c24.363 0 44.114 19.75 44.114 44.114h6c0-27.678-22.437-50.114-50.114-50.114zm44.114 44.114v261.52h6V72.114zm0 261.52c0 24.364-19.751 44.114-44.114 44.114v6c27.677 0 50.114-22.437 50.114-50.114zm-44.114 44.114H242.472v6h115.056zm-115.056 0c-24.363 0-44.114-19.75-44.114-44.114h-6c0 27.677 22.437 50.114 50.114 50.114zm-44.114-44.114V72.114h-6v261.52zm0-261.52C198.358 47.75 218.109 28 242.472 28v-6c-27.677 0-50.114 22.437-50.114 50.114zM303.172 575V164.284h-6V575zm-1.031-270.26-65.209-56.674-3.936 4.529 65.209 56.674zm-.077-99.548 47.456-38.58-3.785-4.655-47.456 38.579z"
    />
  </svg>
);
export default SvgSquareTree;
