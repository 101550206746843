import * as React from "react";
import type { SVGProps } from "react";
const SvgDropDiagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="m226.175 168.221 47.764 54.664m108.589-53.079-46.201 49.903m118.253 92.456-68.209-14.364m-242.4 25.276 83.163-20.877m80.351 111.882-.733-31.225m-.244-207.203c4.461 9.41 18.026 35.685 38.19 51.511 25.97 20.531 41.673 44.667 41.673 75.036 0 38.557-30.185 80.658-79.619 80.658-49.433 0-79.618-42.101-79.618-80.658 0-30.369 15.764-54.505 41.734-75.036 20.531-16.07 33.668-42.345 37.64-51.511m-63.305-49.862c0 33.241-26.947 60.188-60.187 60.188-33.241 0-60.188-26.947-60.188-60.188s26.947-60.188 60.188-60.188c33.24 0 60.187 26.947 60.187 60.188m240.263 0c0 33.241-26.947 60.188-60.187 60.188-33.241 0-60.188-26.947-60.188-60.188s26.947-60.188 60.188-60.188c33.24 0 60.187 26.947 60.187 60.188M575 320.225c0 33.241-26.947 60.188-60.188 60.188s-60.188-26.947-60.188-60.188c0-33.24 26.947-60.187 60.188-60.187S575 286.985 575 320.225M367.674 474.208c0 33.241-26.947 60.188-60.188 60.188s-60.188-26.947-60.188-60.188c0-33.24 26.947-60.187 60.188-60.187s60.188 26.947 60.188 60.187M145.376 336.053c0 33.241-26.947 60.188-60.188 60.188S25 369.294 25 336.053s26.947-60.188 60.188-60.188 60.188 26.947 60.188 60.188"
    />
  </svg>
);
export default SvgDropDiagram;
