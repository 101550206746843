import * as React from "react";
import type { SVGProps } from "react";
const SvgPagoda = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="m409.7 417.736-2.336-124.984-253.706.748 62.69-36.374.654-30.919-44.889.549 80.819-41.338-.151-26.2h11.949v26.305h93.259v-24.191h11.225l.444 25.079 77.327 40.123-45.555.642 1.004 16.003H230.61l-.385 13.164h173.763l60.366 35.989H434.79l.9 42.915-15.548-.105.433-19.986H194.692l1.554 17.416h-32.905l1.016 93.025-17.416.386-.56-78.261 313.394 1.11v91.717H337.84v-47.214s-7.733-19.343-25.92-20.126c-18.187-.782-28.641 19.355-28.641 19.355v46.431l-147.633 1.787v-64.606"
    />
  </svg>
);
export default SvgPagoda;
