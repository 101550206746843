import * as React from "react";
import type { SVGProps } from "react";
const SvgHikerWithCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M315.06 29.62A26.905 26.905 0 0 1 330.161 25c12.333 0 22.725 8.252 25.961 19.542M232.487 170.837h41.709v379.335L249.369 575h70.941V396.263m64.9-39.031v192.94l24.827 24.827h-70.941V396.263m46.114-140.876v62.545h-55.912V147.501m13.088 37.845H316.21m26.176 42.05H316.21m26.176 42.032H316.21M296.704 64.336h-21.951v54.203h85.611V64.336H337.55l-4.836-19.2h-24.882m-46.58 78.006-42.536 32.109c-7.479 6.184-18.535 5.142-24.72-2.337-6.184-7.479-5.141-18.535 2.337-24.72l58.932-42.248m95.103 54.149 23.659 67.022c2.661 9.331 12.369 14.724 21.7 12.081 9.33-2.661 14.724-12.369 12.081-21.699l-29.502-76.46v-23.84m-45.592-8.197c0 7.397-5.996 13.394-13.394 13.394-7.397 0-13.393-5.997-13.393-13.394s5.996-13.393 13.393-13.393c7.398 0 13.394 5.996 13.394 13.393"
    />
  </svg>
);
export default SvgHikerWithCamera;
