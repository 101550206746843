import * as React from "react";
import type { SVGProps } from "react";
const SvgStatueOfLiberty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M391.1 548.73s.853-77.922-3.434-116.457c-40.24 56.511-101.038 62.515-113.023 65.085-11.984 2.57-5.992-13.702-5.992-13.702 70.213-12.848 113.876-80.492 113.876-80.492l4.286-59.934 11.985 32.531 57.376-66.79s-42.822-41.093-42.822-41.958c0-.864-18.829 14.566-18.829 14.566s-13.702 33.396-12.849 36.818c.853 3.423 7.008-77.922-5.992-77.093-28.256 84.779-90.725 98.831-97.92 95.946-8.515-3.399-1.402-12.849-1.402-12.849 59.934-26.55 83.05-105.325 83.05-105.325l-7.709-52.225 36.829-18.841-41.42-6.027 21.727-47.891-39.808 27.706-16.715-56.826-11.576 58.532-38.932-30.639 24.822 53.451-39.505-7.919 33.384 26.503-6.717 44.784-75.352-81.356s-1.717-7.709-19.694-5.992c7.709-15.419 25.698-44.527-13.701-71.066-44.527 45.38-5.14 80.48-5.14 80.48l82.198 128.489v263.249"
    />
  </svg>
);
export default SvgStatueOfLiberty;
