import * as React from "react";
import type { SVGProps } from "react";
const SvgLaptop2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M120.632 345.487h358.822V149.239H120.632v88.71m43.45 160.401h271.922m43.456-52.863H120.638l-95.59 105.728H575.05z"
    />
  </svg>
);
export default SvgLaptop2;
