import * as React from "react";
import type { SVGProps } from "react";
const SvgSeven = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M137.773 503.215h79.972l144.18-330.205H157.657V96.785h304.57v60.371L321.092 480.382c-5.611 13.043-23.926 13.355-30.083.547a16.666 16.666 0 0 1 .974-15.99 16.58 16.58 0 0 1 13.99-7.752h156.254"
    />
  </svg>
);
export default SvgSeven;
