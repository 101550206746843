import * as React from "react";
import type { SVGProps } from "react";
const SvgDesertIsland = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M25.049 468.409c39.823 0 39.823-10.52 79.896-10.52s39.822 10.52 79.895 10.52c40.073 0 39.823-10.52 79.896-10.52s39.823 10.52 79.896 10.52 39.822-10.52 79.895-10.52c40.073 0 40.073 10.52 79.896 10.52m-328.097-28.553c23.042-13.524 56.853-22.04 94.422-22.04 37.568 0 74.385 9.267 97.678 24.044m-96.928-163.799s-24.795 22.04-49.34 45.834c-6.011-39.823 8.265-47.838 23.543-62.865 0 0-54.098-21.289-70.378 13.524 25.546-74.886 73.384-41.074 73.384-41.074s16.029-41.075-18.534-54.85c64.618-14.777 55.1 52.846 55.1 52.846s43.83-49.089 79.395 26.048c-38.821-18.284-61.362-12.022-67.874 0 22.542 7.012 47.587 25.045 28.051 63.365l-23.292-24.545m-30.558 94.172V278.061m232.431-123.475v-22.541m0 139.755v-23.293m-46.336-46.334h-23.293m141.252 0h-24.545m-76.636-29.553-18.283-18.283m96.172 96.175-18.785-18.534m-59.102 0-18.784 18.784m96.921-96.927-19.035 18.784m-12.014 29.554c0 9.682-7.849 17.532-17.532 17.532-9.682 0-17.532-7.85-17.532-17.532 0-9.683 7.85-17.532 17.532-17.532 9.683 0 17.532 7.849 17.532 17.532"
    />
  </svg>
);
export default SvgDesertIsland;
