import * as React from "react";
import type { SVGProps } from "react";
const SvgMountainsWithMouse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M238.27 494.216V575m14.337-40.399h11.179m17.41-.001h140.718c13.987 0 25.333-10.549 25.333-23.556 0-13.007-11.346-23.555-25.333-23.555h-67.278c-13.987 0-25.333-10.549-25.333-23.556 0-13.007 11.346-23.555 25.333-23.555h139.257c13.987 0 25.333-10.549 25.333-23.556 0-13.007-11.346-23.555-25.333-23.555h-38.722l-.332-47.095 26.678 4.784-36.247-82.078-38.988 82.078 26.679-4.784v47.095h-66.248l-18.638 15.731v-40.217l-15.748-52.875-15.748 52.875v16.446H288.87l-13.023 13.023v17.725h-19.852v-20.914l-13.023-13.024h-26.679L203.17 395.16v24.237c-38.706 21.263-92.063 4.087-92.063-24.403 0-77.444 179.075-61.031 247.134-166.516l24.286-34.071 24.287 34.071 24.286-46.762 24.287 46.762 24.286-46.762-72.444-122.795-105.717 179.192L175.777 25 80.774 186.035l24.287 46.762 24.286-34.935 24.287 34.935 24.286-46.762 24.286 46.762 24.287-46.762m54.702 331.156v34.818c0 12.692-10.299 22.991-22.99 22.991h-49.886c-22.293 0-40.399-18.107-40.399-40.4s18.106-40.4 40.399-40.4h49.886c12.691 0 22.99 10.3 22.99 22.991"
    />
  </svg>
);
export default SvgMountainsWithMouse;
