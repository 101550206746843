import * as React from "react";
import type { SVGProps } from "react";
const SvgDna = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M118.987 25c-4.618 52.646 12.231 156.901 99.352 228.147M481.013 575c4.602-52.465-12.116-156.185-98.457-227.411M481.013 25C486.814 91.14 458.734 238.734 300 300c-158.734 61.266-186.814 208.861-181.013 275m56.71-501.004h249.501M218.339 173.999h164.217m41.747 352.005H174.802m206.859-100.003H217.444"
    />
  </svg>
);
export default SvgDna;
