import * as React from "react";
import type { SVGProps } from "react";
const SvgMountainPeak = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M355.164 210.319H575c0-13.86-24.358-25.181-55.439-26.416 2.058-16.467-3.088-31.631-15.095-39.59-6.244-4.117-13.654-5.763-21.27-5.146.48-25.73-20.447-55.302-54.136-72.935-42.196-22.094-88.51-16.948-103.399 11.527-.892 1.715-1.647 3.499-2.333 5.352a62.027 62.027 0 0 0-17.153-2.402c-38.217 0-69.23 34.375-69.23 76.778 0 21.902 1.922 31.594 5.397 37.747m314.682 349.914c0-3.088-267.522-393.357-267.522-393.357L25 547.481h257.847m-53.93-298.671 26.21 41.716 33.826-37.188 35.541 35.061 29.024-43.089"
    />
  </svg>
);
export default SvgMountainPeak;
