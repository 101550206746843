import * as React from "react";
import type { SVGProps } from "react";
const SvgCaribeener = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m216.774 373.19 40.827 112.014-23.791 9.98 9.563 29.709c5.687 15.041 15.296 28.503 28.502 37.648 19.984 13.833 41.408 15.202 59.814 8.727 29.477-10.375 47.279-40.502 44.494-71.604l-16.572-205.203m-71.094-173.288c-4.619-4.387-8.054-9.61-9.957-15.598-8.844-27.806 18.545-61.345 61.182-74.9 42.638-13.555 84.37-1.996 93.213 25.81 8.843 27.806-18.545 61.345-61.182 74.9-7.265 2.298-14.484 3.876-21.54 4.781l-1.137.14c-68.331 9.4-18.034-46.421.696-25.694 13.253 14.669-10.584 45.144-16.804 51.759-23.884 25.462-25.183 64.734-12.719 77.221 12.673 12.673 50.273-10.537 18.545-40.502-10.213-9.632-25.207-12.186-38.158-6.754l-93.376 39.132c-30.614 8.495-47.14 41.64-35.488 71.187l15.69 47.627-22.096 9.679 30.591 84.045m23.303 15.714c0 9.357-7.585 16.943-16.943 16.943s-16.944-7.586-16.944-16.943c0-9.358 7.586-16.944 16.944-16.944 9.358 0 16.943 7.586 16.943 16.944m51.666-182.319 2.809-15.041 17.849 2.577-1.532 15.226 15.179 11.953 14.437-5.083 6.708 16.735-13.949 6.29-2.739 19.125 11.628 9.957-11.141 14.159-12.417-8.936-17.942 7.172-2.808 15.04-17.849-2.576 1.532-15.226-15.18-11.954-14.437 5.083-6.708-16.734 13.95-6.29 2.739-19.126-11.629-9.957 11.141-14.158 12.418 8.936zm-8.541 35.187c-1.021 7.172 3.946 13.81 11.118 14.854 7.172 1.022 13.81-3.945 14.854-11.117 1.022-7.172-3.945-13.811-11.117-14.855-7.172-1.045-13.811 3.946-14.855 11.118"
    />
  </svg>
);
export default SvgCaribeener;
