import * as React from "react";
import type { SVGProps } from "react";
const SvgNine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M74.018 502.682H241.34c60.965 2.649 93.766.663 142.141-25.493 53.344-34.102 82.501-97.67 82.501-176.469 0-111.906-50.363-204.28-169.973-204.28-90.121 0-151.749 58.603-151.749 135.746 0 84.095 63.947 127.799 135.183 127.799 43.073 0 82.832-24.17 101.718-50.987v8.277c0 59.926-35.452 113.893-99.068 113.893-34.789 0-57.319-10.595-78.525-30.129l-37.44 62.575M299.985 178.55c-38.103 0-58.645 26.487-58.645 49.994 0 35.757 29.488 51.318 61.959 51.318a84.53 84.53 0 0 0 51.356-17.547 30.138 30.138 0 0 0 10.21-14.081 30.107 30.107 0 0 0 .724-17.372c-7.29-26.818-26.507-52.312-65.604-52.312"
    />
  </svg>
);
export default SvgNine;
