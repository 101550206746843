import * as React from "react";
import type { SVGProps } from "react";
const SvgSatellite2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M197.233 218.963v-48.998H25v129.427h172.233v-49.784h44.92V326.8h32.422v19.046c0 14.031 11.375 25.425 25.425 25.425s25.425-11.394 25.425-25.425c0-.505-.018-1.011-.056-1.516h.019v-17.511h32.459v-77.192h44.92v49.784H575V169.983H402.767v48.999h-44.92V88.544H242.171v100.429m216.105 256.908c-40.504 40.505-96.481 65.575-158.294 65.575-61.814 0-117.791-25.051-158.295-65.575m287.422-29.166c-33.077 33.077-78.764 53.582-129.128 53.582-50.364 0-96.051-20.486-129.128-53.582m229.164-29.092c-25.631 25.631-61.009 41.496-100.036 41.496-39.026 0-74.404-15.865-100.035-41.496m55.845-185.012c0 7.543-6.114 13.658-13.657 13.658s-13.658-6.115-13.658-13.658c0-7.542 6.115-13.657 13.658-13.657 7.543 0 13.657 6.115 13.657 13.657"
    />
  </svg>
);
export default SvgSatellite2;
