import * as React from "react";
import type { SVGProps } from "react";
const SvgDonutChart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M261.575 27.734v91.078c-44.188 9.309-82.565 34.395-108.947 69.008l-82.972-38.643c42.455-64.634 111.618-110.29 191.919-121.443M538.434 162.204l-86.111 32.504c-26.35-38.174-66.868-65.993-113.93-75.896V27.734c85.612 11.902 158.587 62.994 200.041 134.47M575 298.672c0 83.44-37.55 158.165-96.764 208.335l-49.061-76.989c34.332-33.395 55.637-79.941 55.637-131.346 0-11.371-1.031-22.523-3.077-33.332l85.299-32.192c5.217 20.992 7.95 42.938 7.95 65.524zM413.414 547.978c-34.566 15.619-72.975 24.288-113.43 24.288C148.114 572.266 25 449.746 25 298.657c0-27.944 4.202-54.888 12.027-80.286l82.941 38.643c-3.14 13.387-4.78 27.319-4.78 41.643 0 101.387 82.894 183.89 184.796 183.89 22.649 0 44.329-4.092 64.4-11.496zM362.869 233.148H227.15v38.69h135.719z"
    />
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M362.869 331.396H227.15v38.69h135.719z"
    />
  </svg>
);
export default SvgDonutChart;
