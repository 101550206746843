import * as React from "react";
import type { SVGProps } from "react";
const SvgDrupalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M311.881 575c88.167 0 199.411-100.075 199.411-207.73 0-107.655-94.321-192.335-119.646-216.495C366.32 126.615 300.994 67.28 300.994 25c-23.906 79.823-72.072 48.084 20.947 144.132 77.397 79.824 106.155 114.406 114.557 143.422 7.337 24.871 15.977 71.888 3.077 94.154a8.402 8.402 0 0 1-14.083.355c-22.012-31.74-46.746-57.558-70.178-79.705-25.326-24.16-68.876-66.559-83.314-82.311-14.439-15.751-27.811-43.464-2.486-66.44 25.326-22.976 42.368-54.479 27.811-72.599-14.556-18.12-39.882-10.896-66.391 19.304-26.509 30.2-62.841 78.639-15.74 129.447 47.101 50.807 127.457 126.723 148.049 164.147 20.592 37.425 1.183 106.708-55.859 108.484-71.361-1.303-92.19-60.992-71.716-100.904 10.177-19.66 25.207-35.648 37.515-48.557 41.775-44.057-6.509-71.534-43.432-29.727-15.74 17.883-30.533 33.635-47.693 61.466a8.998 8.998 0 0 1-7.692 4.337 8.985 8.985 0 0 1-7.693-4.337c-57.634-93.206 77.516-179.069 55.74-217.797-10.887-19.304-35.03-21.791-71.243 16.936-26.036 27.713-124.853 180.728-2.84 302.832 23.787 23.805 50.296 41.333 70.415 49.149"
    />
  </svg>
);
export default SvgDrupalIcon;
