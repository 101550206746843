import * as React from "react";
import type { SVGProps } from "react";
const SvgCurvyHousePlant = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M421.695 155.354c0-14.589-5.575-27.874-14.589-37.837a64.454 64.454 0 0 0 1.66-14.471c0-31.906-23.603-58.357-54.324-62.745C344.954 30.812 331.906 25 317.554 25c-14.351 0-19.689 2.965-27.755 7.947-6.167-2.49-12.81-3.914-19.808-3.914-26.924 0-49.223 20.164-52.426 46.377-16.843 4.981-29.178 20.638-29.178 39.141 0 18.504 6.286 24.909 16.012 32.381-15.538 8.659-26.094 25.383-26.094 44.48 0 28.11 22.773 50.884 50.884 50.884s4.389-.119 6.524-.475c6.168 23.96 27.992 41.751 53.968 41.751 25.976 0 18.978-2.49 26.925-6.998 4.388.831 8.895 1.186 13.521 1.186 42.819 0 77.572-34.753 77.572-77.571 0-42.819-.119-4.982-.356-7.473 8.777-9.963 14.233-23.01 14.233-37.362zM319.571 575H230.85c-23.96-49.817-24.909-81.486 0-130.117h88.721c24.908 51.596 26.094 82.435 0 130.117"
    />
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M265.129 434.325c6.286 2.847 12.454 5.694 17.198 10.557h18.741c-6.88-13.641-19.334-19.334-29.178-23.841-11.15-5.1-15.064-7.591-15.064-14.471 0-13.996 12.217-19.452 32.499-26.687 16.606-5.931 35.346-12.692 35.702-30.958v-.237c0-11.387-13.403-15.657-27.517-20.045-16.25-5.101-28.704-9.726-28.704-18.029 0-8.303 8.065-17.436 19.096-27.044-6.405-.237-12.573-1.423-18.266-3.676-10.082 10.319-16.487 20.638-16.487 30.72 0 19.689 22.773 26.806 39.379 32.143 5.456 1.78 14.589 4.626 16.724 6.643-.712 7.235-10.201 11.149-25.62 16.724-18.978 6.879-42.581 15.301-42.581 40.565 0 25.264 13.284 22.773 23.959 27.755z"
    />
  </svg>
);
export default SvgCurvyHousePlant;
