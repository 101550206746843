import * as React from "react";
import type { SVGProps } from "react";
const SvgHeadWithMediumHair = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M209.599 515.461 70.781 471.41s5.553-54.787 8.884-190.643c3.332-135.857 66.633-180.649 98.469-205.821 31.835-25.172 61.449-5.553 82.179 5.553 45.902-54.787 148.813-16.287 187.311 65.893 38.499 82.18 108.094 235.064 71.076 299.846-37.018 64.782-126.973 88.473-126.973 88.473s34.058-135.487-2.22-265.791c-36.278-130.303-138.078-91.063-138.078-91.063s-52.566-10.737-72.186 18.878c-18.854 28.46-24.51 114.377-73.297 114.377-15.177 4.812-24.063 27.392-19.62 52.564 4.442 25.173 21.84 45.532 38.868 42.571h.741c34.797 81.81 106.613 138.818 156.217 138.818 39.64 0 85.022-26.442 119.941-71.638m-75.517-26.823s-25.543 14.067-47.013 13.326c-21.471-.74-44.792-13.326-44.792-13.326"
    />
  </svg>
);
export default SvgHeadWithMediumHair;
