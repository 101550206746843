import * as React from "react";
import type { SVGProps } from "react";
const SvgZakimBridge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m266.214 523.939 33.783-92.424 33.782 92.424h46.992l-64.388-176.155m147.508 176.155h-45.39l-86.835-237.568h-37.73v-15.126h32.201V117.329h-32.201v-15.127h32.201l-26.14-26.141-26.141 26.141v169.043l-92.364 252.694h-45.383m-111.109 0 224.209-323.833M62.822 523.939l193.951-280.131M100.644 523.939l121.963-176.155M575 523.939 350.791 200.106m186.387 323.833L343.227 243.808m156.129 280.131L377.393 347.784"
    />
  </svg>
);
export default SvgZakimBridge;
