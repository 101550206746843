import * as React from "react";
import type { SVGProps } from "react";
const SvgHeadWithBun = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M132.39 339.42c-14.437 4.813-22.952 17.029-18.88 42.942 4.072 25.912 21.101 47.012 37.389 44.051h.741c33.316 84.401 82.92 143.26 150.293 143.26s119.938-58.859 151.033-142.89c14.437-1.851 28.134-20.36 31.836-44.421 3.702-24.062-2.222-39.609-13.697-47.753m-289.11-207.294c0-53.565 51.876-96.988 115.867-96.988 63.992 0 115.866 43.423 115.866 96.988m-65.152 350.93s-14.437 19.991-46.273 19.991c-31.835 0-46.272-19.991-46.272-19.991M97.221 336.092s88.844 22.581 119.199-76.997c55.527 26.653 172.874 111.053 269.491 67.742 34.797-18.139 37.759-210.633-186.571-216.926C75.01 103.618 97.221 336.092 97.221 336.092"
    />
  </svg>
);
export default SvgHeadWithBun;
