'use client'
import { createContext, ReactNode, useContext } from "react";

export type StageContext = {
  stage: string
}

const StageContext = createContext<StageContext>({
  stage: 'production'
})

export const useStage = () => useContext(StageContext);

export const StageProvider = (props: StageContext & {
  children: ReactNode
}) => {
  return <StageContext.Provider value={props}>
    {props.children}
  </StageContext.Provider>
}