import * as React from "react";
import type { SVGProps } from "react";
const SvgParachuteWithLaptop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m138.72 198.894 99.593 185.93m-31.959-185.93 50.663 185.93m38.153-2.601 50.292-183.329m-69.492 0v185.93m47.813 12.882v39.515h-95.629v-52.397h85.719l108.14-202.281a146.776 146.776 0 0 0 1.363-19.943c0-85.967-72.836-137.002-147.283-137.373-74.57.371-147.282 51.406-147.282 137.373 0 85.966.495 13.625 1.362 19.943 0 0 6.689-35.799 35.428-35.799 28.738 0 36.789 35.427 36.789 35.427s7.928-35.055 36.79-35.055 36.79 35.427 36.79 35.427 9.909-35.427 36.789-35.427c26.88 0 36.79 35.427 36.79 35.427s8.795-35.427 36.79-35.427c27.994 0 12.758 2.229 17.341 5.574M220.103 446.016l-17.466 19.324h146.664l-17.218-18.952m-92.408 4.831h72.465m-52.522-29.234-11.148-11.148 10.9-10.901m32.95 22.049 11.025-11.148-10.777-10.901m-21.678 22.049 10.405-22.049m-180.602 168.96s-31.216 4.212-31.34-8.299c-.124-12.511 26.26-8.423 26.26-8.423-4.459-20.563 30.845-43.603 47.072-12.759 13.75-17.837 34.931-12.139 37.533 7.061 38.895-11.644 51.158 28.242 0 20.067-28.491 9.662-48.186 12.387-79.649 2.353zM62.417 367.482s-24.527 3.345-24.65-6.689c-.125-10.033 20.686-6.813 20.686-6.813-3.469-16.598 24.278-35.055 37.037-10.281 10.777-14.369 27.499-9.662 29.481 5.698 30.596-9.29 40.135 22.668 0 16.103-22.42 7.804-37.904 9.91-62.678 1.858zm372.232-54.255s-35.056 4.831-35.18-9.538c-.124-14.369 29.605-9.662 29.605-9.662-5.078-23.66 34.684-50.044 52.893-14.617 15.484-20.439 39.267-13.874 42.24 8.175 43.727-13.254 57.477 32.455 0 23.041-32.082 11.148-54.131 14.121-89.558 2.601m-51.778 228.418s-34.065 4.583-34.189-9.043c-.124-13.626 28.738-9.29 28.738-9.29-4.831-22.545 33.693-47.566 51.407-13.874 15.112-19.447 38.152-13.254 41.001 7.68 42.488-12.634 55.866 30.844 0 21.926-31.092 10.529-52.645 13.502-87.081 2.601z"
    />
  </svg>
);
export default SvgParachuteWithLaptop;
