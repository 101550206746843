import * as React from "react";
import type { SVGProps } from "react";
const SvgPersonLookingAtScreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M25 441.008h126.017v-26.759a7.089 7.089 0 0 0-7.095-7.092H43.875a18.93 18.93 0 0 1-13.35-5.556A18.906 18.906 0 0 1 25 388.245V211.093c0-5.008 1.987-9.811 5.526-13.356a18.93 18.93 0 0 1 13.348-5.556h288.174a18.93 18.93 0 0 1 13.38 5.539 18.91 18.91 0 0 1 5.542 13.373v177.152a18.91 18.91 0 0 1-5.542 13.373 18.926 18.926 0 0 1-13.38 5.539h-97.067a7.142 7.142 0 0 0-7.143 7.092v49.642a12.81 12.81 0 0 1-3.754 9.06 12.823 12.823 0 0 1-9.065 3.753h-47.304c-6.244 0-8.23-8.416-2.649-11.205l61.732-24.16h182.781c4.412.013 8.64 1.77 11.76 4.889a16.686 16.686 0 0 1 4.891 11.753 16.639 16.639 0 0 1-4.894 11.738 16.657 16.657 0 0 1-11.757 4.857 15.568 15.568 0 0 1-11.005-4.556 15.551 15.551 0 0 1-4.558-10.998V322.954a36.627 36.627 0 0 1 10.738-25.909 36.666 36.666 0 0 1 25.922-10.732h12.063c3.45 0 6.759-1.37 9.198-3.808a12.999 12.999 0 0 0 3.81-9.194v-28.84a13.007 13.007 0 0 0-13.008-13.001h-16.651a9.124 9.124 0 0 1-9.13-9.078v-24.916h-9.46a6.432 6.432 0 0 1-6.434-6.43 6.336 6.336 0 0 1 .757-2.978l18.259-34.372a57.367 57.367 0 0 1 35.762-28.461 59.84 59.84 0 0 1 14.995-1.939c20.766 0 38.269 11.583 46.547 23.829 6.339-2.033 11.92-2.932 16.887-2.932 20.814 0 29.707 16.406 29.707 35.459 0 14.089-6.764 27.895-6.764 41.322 0 10.165 3.926 19.999 17.786 29.029a43.688 43.688 0 0 1-13.245 2.08c-21.523 0-42.763-16.737-42.763-36.073 0-12.577 7.427-26.193 7.427-39.147 0-5.815-1.514-11.489-5.866-16.831m-59.791 32.291s14.333 5.957 14.333-9.456-6.623-19.289-13.245-12.67c-6.623 6.619-15.138-3.263-8.941-11.064 6.197-7.801-.804-19.999-18.921-7.848zm-43.756 15.46c6.811-1.371 12.866-4.681 11.116-12.292zm7.899-40.991c-2.034 0-3.737 2.128-3.737 4.681s1.703 4.633 3.737 4.633 3.69-2.08 3.69-4.633-1.656-4.681-3.69-4.681"
    />
  </svg>
);
export default SvgPersonLookingAtScreen;
