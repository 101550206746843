import * as React from "react";
import type { SVGProps } from "react";
const Svg1Plus2Equals3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m138.665 352.246 30.413.675a9.21 9.21 0 0 0 6.641-2.618 9.198 9.198 0 0 0 2.768-6.578v-93.26h-15.393l-27.526 27.517 10.27 10.267 19.794-19.788c2.003-1.77 2.189-3.865 1.397-5.355-1.723-3.189-6.59-1.862-6.59 1.746v70.818h52.839a6.812 6.812 0 0 0 4.808-1.991 6.786 6.786 0 0 0 1.992-4.807v-26.888H203.73v-9.126h16.348v-17.6h10.13v17.46h16.348v9.033h-16.348v18.95h-4.495a2.581 2.581 0 0 1-2.398-1.63c-.373-.954-.35-1.746.116-2.467.908-1.397 2.702-1.537 3.912-.373l10.969 10.616a31.303 31.303 0 0 0 21.774 8.823h64.925v-15.923c-4.984 0-28.201.186-43.059.186-4.028 0-4.028 4.47 0 4.47l8.384-5.308c5.729-3.725 34.023-23.187 34.023-43.068 0-22.093-22.589-27.494-32.905-27.494-10.317 0-21.937 3.282-32.742 14.084 4.75 5.89 10.316 11.943 10.316 11.943s8.663-9.824 22.752-9.824c14.089 0 14.089 10.476 14.089 10.476-.303 12.594-12.319 22.046-45.201 46.001v16.948a10.919 10.919 0 0 0 9.431 10.802c41.661 5.587 74.613 9.452 96.247.442 10.083-4.19 16.604-14.107 16.604-25.049v-18.764a4.239 4.239 0 0 0-4.239-4.213h-34.977v8.241h32.136v-24.514h-32.742v8.427l33.907-.209a10.318 10.318 0 0 0 7.009-2.817l22.985-16.762.047-.046c6.031-5.774 13.46-8.987 21.797-8.987 8.988 0 15.928 3.469 15.928 10.267 0 6.425-6.287 9.498-15.789 9.498-3.33 0-9.105 0-10.642-.139v15.923c1.281-.14 7.056-.14 10.642-.14 11.923 0 17.186 3.213 17.186 10.151 0 6.541-5.775 11.011-16.161 11.011-8.337 0-17.699-3.585-23.614-9.731l-9.501 11.919c6.288 7.706 17.978 13.736 33.767 13.736 20.516 0 33.743-10.407 33.743-25.143 0-12.827-11.294-19.625-20.027-20.533 9.501-1.676 18.747-8.73 18.747-19.509 0-14.224-12.319-23.21-32.347-23.21-15.02 0-25.802 5.774-32.718 13.596.838 1.094 1.583 1.979 2.258 2.793a1.775 1.775 0 0 0 1.588.651 1.771 1.771 0 0 0 1.393-1c2.748-5.703 3.517-9.801 12.389-19.625"
    />
  </svg>
);
export default Svg1Plus2Equals3;
