import * as React from "react";
import type { SVGProps } from "react";
const SvgSuperBowlTrophy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m149.897 497.184 45.094.002c2.751 0 36.08-257.169 39.558-284.026.162-1.537.001-2.993-.323-4.449l-2.427-8.575c-.647-2.346-2.022-4.369-3.883-5.906-9.465-7.685-17.474-17.797-24.835-27.424-10.597-13.752-20.062-28.637-27.181-44.492-6.31-14.238-9.788-29.042-8.98-44.655.567-11.892 2.913-24.673 10.193-34.462a30.568 30.568 0 0 1 7.685-7.442c23.379-15.775 120.131-21.276 170.286 34.3 50.156 55.575 55.576 113.254 49.428 134.53-4.126 14.076-15.128-68.357-57.032-105.246l9.465-8.494-12.701 2.265-19.981-22.812c.162.485 8.737-7.038 8.737-7.038l-12.943 2.507-19.496-13.59 9.303-6.31-15.694 1.456-18.525-9.546s6.309-5.015 5.824-4.692c-.485.324-11.811 1.052-11.811 1.052s-9.222-3.236-19.9-4.126c-2.023-.161 13.671 7.038 13.671 7.038l-9.869 10.76 17.554-8.414 18.202 10.598-12.377 13.914 18.687-9.222 18.201 14.56s-11.972 11.65-11.487 11.65c.486 0 16.584-8.009 16.584-8.009l19.415 23.784-10.678 11.649 14.399-6.957s31.792 33.814 53.392 106.378c1.779 5.986-146.341-17.312-213.161-107.511-6.391-3.236-6.391 2.912-5.339 4.449 1.051 1.537 59.054 100.23 217.448 111.879-39.477 11.326-119.645 14.076-144.885-8.898-1.537 6.148-35.837 310.479-35.837 310.479l5.501 47.162s38.345-342.19 38.668-341.219c.324.971 28.638 15.451 70.137 11.244 2.023 13.348 32.44 294.785 32.44 294.785s-104.599 7.362-124.661 27.99c-20.062 20.629 65.202 16.503 66.254 16.665 1.051.162 87.287-1.214 108.077-72.564l40.009-.003"
    />
  </svg>
);
export default SvgSuperBowlTrophy;
