import * as React from "react";
import type { SVGProps } from "react";
const SvgBarGraph = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M119.574 380.37H25v152.334h94.574zM271.378 162.918h-94.575v370.035h94.575zM423.197 253.634h-94.575v279.319h94.575zM575 67.047h-94.575v465.656H575z"
    />
  </svg>
);
export default SvgBarGraph;
