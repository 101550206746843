import * as React from "react";
import type { SVGProps } from "react";
const SvgPercentSign = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M113.588 492.653h92.236L349.306 78.99h52.64L258 492.653c1.762 0 145.167 48.554 198.431-3.504 27.131-26.516 29.66-65.829 29.966-84.835.69-52.572-23.375-107.902-81.008-108.668-57.633-.767-83.001 54.027-83.691 106.522-.383 27.972 6.132 50.886 17.628 69.432a72.034 72.034 0 0 0 10.499 13.411c22.779 22.777 56.248 32.839 106.606 35.998M404.929 333.58c-29.889-.383-41.002 30.884-41.462 66.289-.46 35.406 9.81 67.209 39.7 67.593 29.889.383 41.079-31.191 41.538-66.52.46-35.329-9.886-66.979-39.776-67.362m-156.192-61.078c27.131-26.516 29.66-65.83 29.89-84.835.69-52.572-23.299-107.902-80.932-108.669-57.633-.766-83.077 53.951-83.767 106.523-.383 27.972 6.131 50.886 17.627 69.431 28.102 45.335 80.966 52.603 117.182 17.55m-51.578-155.569c-29.89-.384-41.002 30.884-41.462 66.289-.46 35.405 9.81 67.209 39.776 67.592 29.966.383 41.002-31.19 41.462-66.519.46-35.329-9.81-66.979-39.776-67.362"
    />
  </svg>
);
export default SvgPercentSign;
