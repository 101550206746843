import * as React from "react";
import type { SVGProps } from "react";
const SvgAirplane = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="m25 291.598 142.537 42.811 109.529-.7-97.592 123.565h51.946l159.376-122.165s136.902 0 138.303 1.401c1.4 1.4 40.01-.7 45.645-33.676 5.635-32.975-83.555-56.881-94.792-57.582-11.236-.7-120.731 0-120.731 0l-125.7-102.527h-62.483l54.481 69.752 48.346.567 37.11 31.308-137.836-1.901-98.993-87.756-46.345.734 45.678 89.823-47.779 1.434"
    />
  </svg>
);
export default SvgAirplane;
