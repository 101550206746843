import * as React from "react";
import type { SVGProps } from "react";
const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M363.219 401.402V153.45h-42.386a20.755 20.755 0 0 1-6.102 14.695 20.857 20.857 0 0 1-14.732 6.087 20.857 20.857 0 0 1-14.731-6.087 20.755 20.755 0 0 1-6.102-14.695h-42.147v293.338h157.332V143.656a23.615 23.615 0 0 0-6.859-16.876 23.748 23.748 0 0 0-16.849-7.011H229.595a23.977 23.977 0 0 0-16.933 6.997 23.854 23.854 0 0 0-7.014 16.89v312.925c0 3.126.621 6.221 1.828 9.106a23.647 23.647 0 0 0 5.201 7.702 23.697 23.697 0 0 0 7.772 5.11 23.758 23.758 0 0 0 9.146 1.731h147.514z"
    />
  </svg>
);
export default SvgPhone;
