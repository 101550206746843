import * as React from "react";
import type { SVGProps } from "react";
const Svg1Plus2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M237.14 353.828a9.493 9.493 0 0 0 9.469-9.477v-96.189h-15.865l-28.34 28.384 10.577 10.562 20.385-20.4c2.079-1.832 2.26-3.981 1.446-5.519-1.763-3.279-6.803-1.922-6.803 1.81v73.029h54.465c1.858 0 3.64-.738 4.954-2.053a7.013 7.013 0 0 0 2.052-4.958v-27.728h-16.859v-9.409h16.859v-18.138h10.441v18.003h16.837v9.295h-16.837v19.564h-4.633a2.642 2.642 0 0 1-2.486-1.719c-.361-.95-.339-1.719.068-2.42.859-1.448 2.893-1.651 4.09-.475l11.3 10.946a32.21 32.21 0 0 0 22.441 9.092h66.895v-16.419c-5.153 0-29.063.181-44.385.181-4.136 0-4.136 4.636 0 4.636 0 0 7.977-5.044 8.633-5.496 5.921-3.845 35.074-23.928 35.074-44.397 0-22.797-23.278-28.361-33.899-28.361-10.622 0-22.622 3.37-33.741 14.52 4.881 6.061 10.621 12.326 10.621 12.326s8.95-10.132 23.459-10.132 14.509 10.788 14.509 10.788c-.317 12.982-12.701 22.752-46.578 47.45v17.483a11.226 11.226 0 0 0 2.778 7.398 11.208 11.208 0 0 0 6.962 3.729"
    />
  </svg>
);
export default Svg1Plus2;
