import * as React from "react";
import type { SVGProps } from "react";
const SvgGrammys = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M389.266 481.104c0 25.328-12.623 49.914-48.841 49.914-36.219 0-52.884-16.665-52.884-16.665l-36.136-98.178-40.261-6.353v-88.524l112.038 17.82 113.77-14.933v101.148l37.209-7.26 63.279 117.235-215.826 31.598-237.193-34.073c-44.387-6.435-11.138 72.602 11.715 28.958l75.737-144.626H84.42M537.436 57.043H322.604c-25.576 0-47.604-3.3-47.604 8.416 0 23.348 42.736 42.241 36.301-16.088-1.237-11.056-5.115-18.563-11.963-22.194-21.533-11.632-63.526 24.504-93.804 80.688-30.279 56.184-37.291 111.13-15.758 122.68 21.533 11.551 63.526-24.503 93.805-80.687a291.577 291.577 0 0 0 16.665-36.796m-50.409 184.64c0-14.19 143.719-14.19 143.719 0v13.86l46.119-10.725c43.148-83.987-90.753-125.898-119.298-189.92-10.478 48.181-37.869 85.307-51.317 99.993 83.822-2.31 128.374 21.45 139.924 33.826l-75.077 13.613c-7.013-12.623-25.576-8.333-24.503 7.012z"
    />
  </svg>
);
export default SvgGrammys;
