import * as React from "react";
import type { SVGProps } from "react";
const SvgRockingHorse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M465.116 502.759a462.076 462.076 0 0 0 40.515-17.131c8.973-4.351 11.421-15.771 5.71-23.929-4.622-6.797-13.867-8.701-20.937-4.894-35.077 18.762-35.893 18.762-35.893 18.762-10.061-9.789-18.762-56.558-24.2-91.635-2.991-19.85 5.438-39.7 21.209-52.208 34.533-39.971 7.614-81.03 7.614-81.03s8.701-10.333 19.578-10.333c10.876 0 45.681 3.535 20.937 69.61-24.744 66.075 22.297 101.696 22.297 101.696s31.542-89.732 27.735-149.281c-.544-7.885-2.991-15.771-7.07-22.569-5.982-10.333-15.771-18.49-26.919-22.297-33.174-11.42-62.54-.272-62.54-.272-16.587 5.982-22.297 14.684-22.297 14.684s-88.917-31.542-152.545-24.201c-41.331 4.895-56.83-64.715-57.102-67.163-7.885-57.374-71.785-74.232-129.703-69.61 0 0 24.201 26.104 3.807 55.743-20.394 29.638-66.89 66.347-33.717 99.52 30.182 29.911 81.846-22.568 81.846-22.568s8.429 12.508 14.411 40.515c8.974 42.962 3.535 87.828-13.323 128.344l-32.63 77.767m-.544.544c-15.771-8.973-28.823-18.218-37.252-24.744-6.526-5.167-16.043-3.535-20.666 3.535-4.35 6.254-1.36 13.323 2.991 19.577 18.49 27.464 173.482 109.31 352.13 70.154M76.82 149.814l66.075 54.383 78.039-63.084m36.167 67.162s-39.971 64.987-26.919 78.311c13.051 13.324 69.882 29.367 119.37 14.684 49.488-14.684 0-85.653 0-85.653M142.897 466.322s64.444-128.344 74.505-129.431c8.429-1.088 49.488 34.533 148.193 11.148 0 0 62.812 136.501 44.05 141.396-114.748 28.551-230.583-8.702-266.748-23.113m-20.394-310.799c-5.982 0-10.876-5.166-10.876-11.148 0-5.982 4.894-10.877 10.876-10.877 6.255 0 11.149 4.895 11.149 10.877 0 6.254-4.894 11.148-11.149 11.148"
    />
  </svg>
);
export default SvgRockingHorse;
