import * as React from "react";
import type { SVGProps } from "react";
const SvgSix = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M170.808 504.885H308.44c87.139 0 143.799-59.502 143.799-133.271 0-84.431-60.177-127.862-130.357-127.862-41.185 0-78.229 22.813-98.158 46.723a3.973 3.973 0 0 1-4.383 1.192 3.996 3.996 0 0 1-2.65-3.701v-.156c0-69.615 37.981-130.841 102.925-130.841 32.824 0 54.003 10.113 72.525 27.516a8.577 8.577 0 0 0 10.589.862 8.619 8.619 0 0 0 2.618-2.743l22.82-37.551a8.568 8.568 0 0 0-1.641-10.897c-25.946-23.44-61.583-39.041-106.911-39.041-112.85 0-171.855 90.389-171.855 205.159 0 87.228 31.99 146.306 80.215 185.073m155.333-111.303c0 32.064-26.884 68.988-78.307 68.988-29.307 0-49.705-11.838-63.459-28.928-29.072-35.983-13.13-90.389 30.557-105.52a95.398 95.398 0 0 1 31.104-5.331c42.671 0 80.105 22.029 80.105 70.791"
    />
  </svg>
);
export default SvgSix;
