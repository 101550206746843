import * as React from "react";
import type { SVGProps } from "react";
const SvgPersonFromFront = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m369.7 372.702-6.975-22.739H238.836l-5.925 21.987s-125.517 8.681-178.702 71.531C108.165 522.845 199.13 575 302.312 575c100.311 0 189.089-49.293 243.479-124.967M369.7 372.702c108.376 7.052 156.089 49.139 176.091 77.331M369.7 372.702c108.376 7.052 156.079 49.139 176.091 77.331M210.923 164.189v66.212a89.51 89.51 0 0 0 179.021 0v-66.212m-179.021 0h70.491l21.92-23.953 21.351 23.953h65.259m-179.021 0v66.26a89.51 89.51 0 0 0 179.021 0v-66.26m-89.559 387.822c58.447-.694 89.511-165.059 89.511-165.059m-89.511 165.059c-58.446.693-86.369-124.226-86.369-124.226s27.961 124.929 86.418 124.226zM196.297 77.424c20.099-28.712 58.302-53.205 106.295-52.405 47.713.79 77.765 22.16 101.168 50.257 27.942 33.491 49.563 258.22 49.563 258.22H149.721s27.008-228.149 46.548-256.072zm207.454-2.148c-23.423-28.096-53.475-49.467-101.169-50.257-47.992-.8-86.195 23.693-106.294 52.406-19.54 27.941-46.547 256.071-46.547 256.071h303.592s-21.65-224.728-49.582-258.22"
    />
  </svg>
);
export default SvgPersonFromFront;
