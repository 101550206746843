import * as React from "react";
import type { SVGProps } from "react";
const SvgBird1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M329.807 270.293h173.707m-407.028 0h173.068m-173.068 0c0 5.9-4.782 10.683-10.682 10.683s-10.683-4.783-10.683-10.683c0-5.9 4.783-10.683 10.683-10.683 5.9 0 10.682 4.783 10.682 10.683m364.947 40.29H329.807v27.44l57.61 75.506s-31.573 26.964-87.405 26.964c-55.831 0-87.404-26.964-87.404-26.964l56.959-75.506m191.866-27.44c0 5.9 4.783 10.682 10.683 10.682 5.9 0 10.683-4.783 10.683-10.683 0-5.9-4.783-10.683-10.683-10.683-5.9 0-10.683 4.784-10.683 10.684m-322.866 0h130.987m-130.987 0c0 5.9-4.783 10.682-10.683 10.682-5.9 0-10.683-4.783-10.683-10.683 0-5.9 4.783-10.683 10.683-10.683 5.9 0 10.683 4.784 10.683 10.684m-92.201-80.592H282.09v-32.95a17.827 17.827 0 0 1 6.011-13.376h.013L300 159.507l11.885 24.158v-.025a17.87 17.87 0 0 1 6.024 13.401v32.95h235.725m-507.268 0c0 5.9-4.783 10.683-10.683 10.683-5.9 0-10.683-4.783-10.683-10.683 0-5.9 4.783-10.682 10.683-10.682 5.9 0 10.683 4.782 10.683 10.682m507.268 0c0 5.9 4.783 10.683 10.683 10.683 5.9 0 10.683-4.783 10.683-10.683 0-5.9-4.783-10.682-10.683-10.682-5.9 0-10.683 4.782-10.683 10.682m-28.742 40.302c0 5.9-4.783 10.683-10.683 10.683-5.9 0-10.683-4.783-10.683-10.683 0-5.9 4.783-10.683 10.683-10.683 5.9 0 10.683 4.783 10.683 10.683"
    />
  </svg>
);
export default SvgBird1;
