import * as React from "react";
import type { SVGProps } from "react";
const SvgMountains = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m25 441.953 55.37-37.836 29.945 23.071 79.87-79.455 79.362 71.149 50.34-36.497 59.475 48.494 67.874-52.185L575 443.799M208.502 373.71l-9.689 8.306-11.074-7.844-8.121 8.766-5.768-8.305m89.453-140.915c.369-1.43.554-2.953.554-4.521 0-10.382-8.444-18.826-18.826-18.826-4.981 0-9.228 1.8-12.55 4.799-10.29-9.552-28.469-15.919-49.14-15.919s-7.614.231-11.259.646c-6.832 3.853 0-.415 0-.646 0-23.763-19.287-43.049-43.049-43.049-23.763 0-43.05 19.286-43.05 43.049 5.017 3.949 0 .092 0 .092-.969-.092-1.938-.092-2.953-.092h-1.615c-17.902.738-32.765 13.849-32.206 29.253l.508 5.122h213.54zm294.77 56.477c.046-.6.092-1.2.092-1.846 0-12.689-10.29-22.932-22.932-22.932-4.233 0-5.214.462-7.567 1.292-4.153-10.197-14.166-17.349-25.839-17.349-3.277 0-7.337.738-10.613 2.076-5.767-10.566-16.98-17.718-29.807-17.718-18.779 0-33.96 15.227-33.96 33.96 4.553 3.172 0 .323 0 .508-7.659 1.891-13.334 8.812-13.334 17.026 0 8.213.231 3.368.692 4.937h143.314z"
    />
  </svg>
);
export default SvgMountains;
