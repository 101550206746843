import * as React from "react";
import type { SVGProps } from "react";
const SvgTablet2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M380.848 390.566V126.247H152.014v347.959h228.834v-57.57h32.782l-.001 44.904c0 7.093 5.827 12.667 12.92 12.667h8.614c7.094 0 12.921-5.574 12.921-12.667V288.764l-17.227-47.627-17.227 47.627v101.802m-167.836 26.07h44.535m-80.38-231.008c0 8.198-6.646 14.845-14.845 14.845-8.199 0-14.845-6.647-14.845-14.845 0-8.199 6.646-14.846 14.845-14.846 8.199 0 14.845 6.647 14.845 14.846m42 0c0 8.198-6.646 14.845-14.845 14.845-8.199 0-14.845-6.647-14.845-14.845 0-8.199 6.646-14.846 14.845-14.846 8.199 0 14.845 6.647 14.845 14.846m41.639 0c0 8.198-6.646 14.845-14.845 14.845-8.199 0-14.845-6.647-14.845-14.845 0-8.199 6.646-14.846 14.845-14.846 8.199 0 14.845 6.647 14.845 14.846"
    />
  </svg>
);
export default SvgTablet2;
