import * as React from "react";
import type { SVGProps } from "react";
const SvgRoundTree = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M299.927 575V164.143m0 142.783-65.194-56.589m65.194-47.398 47.547-38.796M299.052 25h1.896c57.271 0 103.699 46.427 103.699 103.699v148.474c0 57.271-46.428 103.699-103.699 103.699h-1.896c-57.271 0-103.699-46.428-103.699-103.699V128.699C195.353 71.427 241.781 25 299.052 25"
    />
  </svg>
);
export default SvgRoundTree;
