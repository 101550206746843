import * as React from "react";
import type { SVGProps } from "react";
const SvgMedal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M221.268 244.142 130.974 25H288.49l90.204 219.141m-78.702 32.599v-32.598l-52.338-127.023m52.338 159.621c-82.363 0-149.13 66.768-149.13 149.13 0 35.888 12.676 68.815 33.796 94.548M299.992 276.74c82.362 0 149.13 66.768 149.13 149.13S382.354 575 299.992 575c-32.551 0-62.667-10.429-87.187-28.128m38.312-302.73h127.615L469.026 25H321.983m-112.286 0 25.184 61.12M390.286 25l-34.58 83.925m27.453 368.397-18.566 11.028a6.082 6.082 0 0 0-2.122 2.122l-11.028 18.566a6.08 6.08 0 0 1-5.152 2.974l-21.592.268a6.067 6.067 0 0 0-2.9.777l-18.833 10.564a6.077 6.077 0 0 1-5.949 0l-18.833-10.564a6.067 6.067 0 0 0-2.9-.777l-21.592-.268a6.08 6.08 0 0 1-5.152-2.974l-11.028-18.566a6.082 6.082 0 0 0-2.122-2.122l-18.566-11.028a6.081 6.081 0 0 1-2.975-5.152l-.267-21.593a6.085 6.085 0 0 0-.777-2.899l-10.565-18.833a6.082 6.082 0 0 1 0-5.949l10.565-18.834a6.085 6.085 0 0 0 .777-2.899l.267-21.592a6.079 6.079 0 0 1 2.975-5.152l18.566-11.028a6.085 6.085 0 0 0 2.122-2.123l11.028-18.566a6.08 6.08 0 0 1 5.152-2.974l21.592-.267a6.09 6.09 0 0 0 2.9-.777l18.833-10.565a6.082 6.082 0 0 1 5.949 0l18.833 10.565a6.09 6.09 0 0 0 2.9.777l21.592.267a6.08 6.08 0 0 1 5.152 2.974l11.028 18.566a6.085 6.085 0 0 0 2.122 2.123l18.566 11.028a6.079 6.079 0 0 1 2.975 5.152l.267 21.592a6.085 6.085 0 0 0 .777 2.899l10.565 18.834a6.082 6.082 0 0 1 0 5.949l-10.565 18.833"
    />
  </svg>
);
export default SvgMedal;
