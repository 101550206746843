import * as React from "react";
import type { SVGProps } from "react";
const SvgToolbox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m488.289 347.052 86.76 23.804-265.604 145.017-200.455-99.915-12.216-52.619c-2.819-12.529 9.397-23.178 21.612-18.793 14.721 5.638 13.468 26.623-1.566 30.695l-45.729 12.215-46.042-283.457L289.713 84.58l5.638 27.876M131.853 255.281l196.697 47.608v73.918l-186.988-50.114m217.368 38.839 180.097-68.594v-67.653m0 0L356.111 293.18m182.916-63.895-92.396-18.793m19.107 160.677-185.422 77.99-73.604-32.574 57.317-24.118m26.623-143.451-93.65-63.582c3.445-15.974-2.819-33.514-17.227-43.224-13.781-9.396-31.321-9.083-44.476-.626l31.008 20.985-18.792 28.189-31.008-20.985c-2.506 15.347 3.758 31.635 17.539 41.031 14.408 9.709 32.261 9.083 45.729 0l72.039 49.174m148.151-8.456c5.324-6.577 6.577-15.034 3.132-22.238l57.318-71.725 20.672-11.276 18.792-44.789-15.347-12.216-37.899 27.563-5.951 25.37-57.944 68.907c-7.83-1.566-17.54 1.253-22.865 7.83l-45.415 57.005"
    />
  </svg>
);
export default SvgToolbox;
