import * as React from "react";
import type { SVGProps } from "react";
const SvgSuitcase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M575 520.565H25v-327.66c0-7.801 6.383-13.83 13.83-13.83H561.17c7.801 0 13.829 6.383 13.829 13.83z"
    />
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M419.681 520.565h48.936v-341.49h-48.936zM132.447 520.565h48.936v-341.49h-48.936zM401.951 112.059v67.022h-47.518v-55.319H246.987v55.319h-45.391v-67.022a32.54 32.54 0 0 1 32.625-32.624h135.461a32.538 32.538 0 0 1 32.624 32.624z"
    />
  </svg>
);
export default SvgSuitcase;
