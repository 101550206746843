import * as React from "react";
import type { SVGProps } from "react";
const SvgPlusSign = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M265.348 444.373V316.314H153.506v-62.435h111.842V133.467h69.304v119.456h111.842v61.798H334.652v129.65h-30.749a17.691 17.691 0 0 1-16.41-11.149c-2.549-6.53-2.389-11.946.797-16.883 6.213-9.557 18.481-10.512 26.766-2.549 6.929 8.453 11.123 29.716-27.534 47.143-15.981 5.036-56.062 11.248-88.544-4.195"
    />
  </svg>
);
export default SvgPlusSign;
