import * as React from "react";
import type { SVGProps } from "react";
const SvgLaptopWithCodeSymbols = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M410.844 182.551h-250.56v153.16H439.81V220.207M136.751 361.418l-51.054 56.485H514.4l-50.329-55.399m-270.11 14.122h211.817m-153.524-85.451-32.587-32.587 31.863-31.864m96.315 64.451 32.225-32.587-31.501-31.864m-63.367 64.451 30.414-64.451"
    />
  </svg>
);
export default SvgLaptopWithCodeSymbols;
