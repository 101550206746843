import * as React from "react";
import type { SVGProps } from "react";
const SvgFive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M206.465 397.91c18.928 11.302 49.41 24.815 82.596 21.867 84.809 0 103.737-116.214 1.721-116.214-49.656 0-92.675 30.712-92.675 30.712l-49.657-17.199V96.441h268.931v71.743H229.08v100.981s49.656-35.871 104.475-35.871c54.819 0 117.995 46.19 117.995 121.619s-37.611 148.646-145.527 148.646-157.573-49.63-157.573-49.63"
    />
  </svg>
);
export default SvgFive;
