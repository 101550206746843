import * as React from "react";
import type { SVGProps } from "react";
const SvgMagnifyingGlass = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M280.76 373.235c72.26 47.357 171.145 38.13 235.838-27.043 74.059-74.618 75.362-194.281 2.9-267.286C447.036 5.902 328.21 7.22 254.151 81.821c-64.709 65.174-73.873 164.772-26.858 237.56m53.467 53.854a184.028 184.028 0 0 1-29.509-24.128m29.509 24.128L91.953 563.422c-15.088 15.196-39.294 15.476-54.056.589-14.762-14.87-14.499-39.247.589-54.443l188.807-190.187m23.958 29.726a184.542 184.542 0 0 1-23.958-29.726m23.958 29.726a183.733 183.733 0 0 1-23.958-29.726m41.139-51.063 75.439-82.928 83.006 72.058 69.5-107.428"
    />
  </svg>
);
export default SvgMagnifyingGlass;
