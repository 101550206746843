import * as React from "react";
import type { SVGProps } from "react";
const SvgFirstPlaceBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M332.41 397.656h31.028m-31.028 0h-66.49m66.49 0v-52.084m-66.49 52.084h-33.244m33.244 0V263.569h-33.244l99.734-74.247v104.167M300 112.567c103.516 0 187.433 83.917 187.433 187.433m-5.503 45.269C461.681 426.915 387.908 487.434 300 487.434c-21.746 0-42.627-3.704-62.046-10.514M200.2 458.684a188.347 188.347 0 0 1-35.86-29.348m-25.443-33.483c-16.723-28.046-26.33-60.827-26.33-95.853 0-86.769 58.96-159.767 139.002-181.116M382.2 190.92c33.028 24.929 54.377 64.511 54.377 109.08 0 33.081-11.762 63.416-31.332 87.05m-210.49 0c-19.569-23.634-31.331-53.969-31.331-87.05 0-44.569 21.348-84.151 54.376-109.08M300 25l55.074 33.705 64.244-6.471 34.996 54.263 60.69 22.043 7.986 64.074 45.115 46.193L547.5 300l20.605 61.193-45.115 46.193-7.986 64.074-60.69 22.043-34.996 54.263-64.244-6.471L300 575l-55.074-33.705-64.244 6.471-34.996-54.263-60.69-22.043-7.986-64.074-45.115-46.193L52.5 300l-20.605-61.193 45.115-46.193 7.986-64.074 60.69-22.043 34.996-54.263 64.244 6.471z"
    />
  </svg>
);
export default SvgFirstPlaceBadge;
