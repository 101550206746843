import * as React from "react";
import type { SVGProps } from "react";
const SvgPersonFromSide = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M182.903 76.123c-20.736 29.651-36.615 271.738-36.615 271.738m36.615-271.738c15.725-22.463 41.9-42.473 74.966-51.113h72.513M182.903 76.124c-20.736 29.631-36.615 271.738-36.615 271.738m36.615-271.738C198.628 53.63 224.824 33.65 257.87 25M146.288 347.861l291.493-.511m0 0s-5.113-237.966-34.703-273.507m34.703 273.507s-5.051-237.966-34.703-273.507m0 0c-18.629-22.35-41.225-40.694-72.696-48.832m72.696 48.832c-18.629-22.35-41.225-40.653-72.696-48.832M198.454 168.195h41.062l18.66-25.418 22.657 25.418m-82.379 0 .051 70.315c0 52.452 18.762 95.702 59.201 94.986 52.462 0 77.093-42.534 77.093-94.986v-70.315h-53.966m-82.379 0v70.315c0 52.452 18.762 95.702 59.2 94.986 52.463 0 77.094-42.534 77.094-94.986v-70.314l-53.915-.001M113.703 575a8622.071 8622.071 0 0 0 26.666-79.026c32.586-98.156 81.418-107.286 81.418-107.286l6.288-23.333h131.468l7.402 24.13c107.931-.572 115.19 112.501 115.19 112.501m0 0 4.162 72.994H113.703a8632.52 8632.52 0 0 0 26.666-79.026c32.586-98.084 81.418-107.286 81.418-107.286l6.288-23.333h131.468l7.402 24.13c107.931-.552 115.19 112.521 115.19 112.521m-267.567-54.067s-36.205 121.458-1.901 121.09c62.022-.655 88.391-157.602 135.424-169.023"
    />
  </svg>
);
export default SvgPersonFromSide;
