'use client'
import React from 'react';

import LogoSVG from '@/public/assets/logo.svg';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { DrupalMenuLinkContent } from 'next-drupal';
import Link from 'next/link';
import { useSearchUi } from '@/contexts/SearchUiProvider';
import classNames from 'classnames';
import { Session } from 'next-auth';
import { UserNav } from './UserNav.client';
import { useProducts } from '@/contexts/ProductsProvider';
import { usePathname } from 'next/navigation';
import useScreens from '../hooks/useScreens';
import { MobileHeader } from './MobileHeader.client';
import { Menu } from '@headlessui/react';
import { LoginButton } from '../wysiwyg/LoginButton.client';
export const Header = ({ menu, session }: {
  menu: DrupalMenuLinkContent[],
  session: Session | null
}) => {

  const { products } = useProducts()
  const pathname = usePathname()
  const screens = useScreens();

  const { isVisible, showSearchUi, hideSearchUi, current, runSearch } = useSearchUi()

  if (!screens?.lg) {
    return <MobileHeader menu={menu} session={session} />
  }

  const toggleSearch = () => {
    if (!isVisible && !current?.searchPhrase && (!current?.products || current?.products.length == 0)) {
      const inContextProducts = products.filter(p => pathname.startsWith(p.path.alias))
      if (inContextProducts.length) {
        return runSearch(null, [inContextProducts.reduce((a, b) => a.path.alias.length > b.path.alias.length ? a : b).name])
      }
      return showSearchUi()
    }
    else {
      return isVisible ? hideSearchUi() : showSearchUi()
    }
  }
  
  return (
    <header>
      <div className='bg-white'>
        <nav className="p-3 text-navy-600">
            <div className="mx-auto container px-2">
              <div className="relative flex h-16 items-center justify-between">
                <div className="flex flex-1 items-stretch justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <Link className="flex place-items-baseline" href="/">
                      <LogoSVG className='me-1 h-6' />
                      <span className='uppercase text-[30px] font-thin'>Docs</span>
                    </Link>
                  </div>
                  <div className="ml-6">
                    <div className="flex space-x-4">
                      {menu && menu.map((link, i) => {
                        return link.items ?
                          (<DropDown buttonLink={link.url} route={link.route} buttonLabel={link.title} key={`header-menu-item-${i}`} items={link.items ?? []} />)
                          : (<Link key={`header-menu-item-${i}`} className="flex hover:bg-gray-400 p-3 rounded-lg px-3 py-2 text-sm font-medium" href={link.url}>{link.title}</Link>)
                      })}
                    </div>
                  </div>
                </div>
                <div className="flex-grow" />
                <div className='flex gap-x-4'>
                  <LoginButton />
                  <button className={classNames('border w-fit whitespace-nowrap border-white rounded-full p-3 hover:bg-navy-600 hover:text-white transition-all duration-300', isVisible ? 'text-white bg-navy-600' : '')} onClick={toggleSearch}><MagnifyingGlassIcon className="w-[24px]" /></button>
                  <div className='flex-grow flex max-h-[50px]'><div className='flex-grow'/><UserNav session={session} /></div>
                </div>
              </div>
            </div>
          </nav>
      </div>
    </header>
  )
};

interface DropDownProps {
  buttonLabel: string,
  buttonLink: string | null,
  items: DrupalMenuLinkContent[],
  route: {
    name: string
  }
}

export const DropDown = (props: DropDownProps) => {
  return (
    <div className='lg:flex relative'>
      <Menu>
        <Menu.Button className="flex hover:bg-gray-400 p-3 rounded-lg truncate">
          {props.buttonLink && props.route.name != '' ? (
            <>
              <Link href={props.buttonLink}>{props.buttonLabel}</Link>
              <ChevronDownIcon className='ml-2 mt-1 w-4 overflow-visible' />
            </>
          ) : (
            <>{props.buttonLabel} <ChevronDownIcon className='ml-2 mt-1 w-4 overflow-visible' /></>
          )}
        </Menu.Button>
        <Menu.Items className="flex flex-col flex-grow lg:absolute z-10 lg:mt-12 lg:w-56 lg:rounded-md border-gray-600 border-1 bg-white lg:shadow-lg ">
          {props.items.map((link) => {
            const is_external_link: boolean = link.url.indexOf("http") === 0;
            return (
              <Menu.Item key={link.id}>
                {({ active }) => (
                  <Link className={`${active && 'bg-gray-400'} p-3 py-2 text-black after:inline-block`} href={link.url} target={`${is_external_link ? '_blank' : ''}`}>{link.title}</Link>
                )}
              </Menu.Item>
            )
          })}
        </Menu.Items>
      </Menu>
    </div>
  )
}

