import * as React from "react";
import type { SVGProps } from "react";
const SvgVrGoggles = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M472.397 358.852v73.14c0 63.727-96.313 66.985-148.815 19.191-13.035-11.949-33.311-11.949-46.346 0-52.501 47.794-148.815 44.536-148.815-19.191v-73.14zm0 0 45.623-39.104m27.156 54.313c-2.173 4.344-5.432 8.69-9.052 12.31l-83.641 85.089M209.168 313.592l7.965-4.707c39.467-22.087 84.003-33.673 129.625-33.673h213.989m-444.638-27.88c13.759-13.76 32.949-22.449 54.312-22.449s40.553 8.689 54.312 22.449M77.73 208.951c23.897-23.897 56.485-38.38 92.693-38.38s68.795 14.845 92.692 38.38M39.351 170.933c33.673-33.673 79.658-54.312 131.073-54.312 51.053 0 97.399 20.639 131.073 54.312m-135.779 187.92v-73.14"
    />
  </svg>
);
export default SvgVrGoggles;
