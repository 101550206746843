import * as React from "react";
import type { SVGProps } from "react";
const SvgShakingHands6 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M246.136 432.483h19.148l9.096 12.429c2.385 3.255 7.007 3.847 10.131 1.305l5.345 6.772a6.38 6.38 0 0 0 8.851 1.14l15.137-11.402c2.672-2.011 3.333-5.753 1.523-8.565l-1.088-1.688h14.405v-38.202h22.282v15.955l-38.428-7.346v-3.717a9.216 9.216 0 0 0-9.218-9.217h-28.931a9.216 9.216 0 0 0-9.218 9.217v.905h-7.642v10.314l7.016 10.201 25.171-19.375 5.945 7.677c2.646 3.421 7.155 5.562 10.688 3.073m-60.213 20.715v-38.393h-76.238v-75.297c0-12.638-10.244-22.883-22.882-22.883h-7.834v53.207h15.354V335.06a29.003 29.003 0 0 0-9.931-21.847l-20.237-17.66 10.419-17.008-41.683-29.619-20.237 30.664 11.08 11.889c3.978 4.274 11.124 1.419 11.072-4.421a6.401 6.401 0 0 0-6.398-6.337H54.867c-12.638 0-22.882 10.245-22.882 22.883v154.277m42.98.008V337.044m87.953-111.036c-9.148 0-16.155 11.994-16.155 11.994v-51.126s-27.452 4.421-44.807-11.994c-11.864 35.781 15.701 67.176 15.701 67.176l7.869-13.325s-10.306-.557-10.306-20.846c0-5.51 18.017-.148 18.017-.148v31.325c0 18.583 16.512 18.583 16.512 18.583h16.79a7.017 7.017 0 0 0 7.015-7.015V225.8h7.546c2.194 0 3.447-2.507 2.133-4.265l-9-12.003v-8.295c0-5.135.6-13.917-1.001-17.138-16.268-32.822-50.204-26.921-58.177-19.079m452.96 303.932c-22.056-6.206-39.063-23.788-44.503-46.044a157.58 157.58 0 0 1-2.115-64.8l7.268-41.257c.322-1.845.487-3.725.487-5.596 0-18-14.588-32.597-32.596-32.597h-.566a32.61 32.61 0 0 0-25.833 12.717l-42.405 52.641c-4.77 6.197-6.276 14.457-3.508 21.769 2.315 6.118 6.754 9.887 13.299 11.306h43.659c9.078-.009 14.954-9.592 10.845-17.686-5.205-10.236-20.384-8.226-22.743 3.011l-6.188 31.874h-87.092v39.063h93.628c7.355 0 25.938.896 25.938-19.427m-38.785-193.854c1.045.749 2.072 1.645 3.064 2.638 19.471 19.47 6.363 26.381 22.082 38.21 47.027 35.399 127.999-129.871-6.598-129.871-70.998 0-82.948 75.245-37.627 81.347v8.582l-8.982 12.002c-1.315 1.758-.061 4.265 2.132 4.265h7.547v19.184a7.017 7.017 0 0 0 7.015 7.015h16.79"
    />
  </svg>
);
export default SvgShakingHands6;
