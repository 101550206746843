import * as React from "react";
import type { SVGProps } from "react";
const SvgThree = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m191.172 211.275.214-.215c27.756-26.591 61.943-41.387 100.309-41.387 41.366 0 73.302 15.976 73.302 47.284 0 29.593-28.935 43.746-72.659 43.746-15.325 0-41.903.001-48.976-.643v73.339c5.894-.643 32.472-.643 48.976-.643 54.869 0 79.089 14.796 79.089 46.748 0 30.129-26.577 50.716-74.374 50.716-38.366 0-81.447-16.512-108.667-44.818l-43.725 54.897c28.935 35.49 82.733 63.26 155.393 63.26 94.414 0 155.285-47.928 155.285-115.799 0-59.078-51.976-90.387-92.164-94.569 43.724-7.719 86.27-40.207 86.27-89.851 0-65.512-56.692-106.9-148.856-106.9-69.122 0-118.741 26.592-150.569 62.618 3.858 5.039 7.287 9.114 10.395 12.866a8.137 8.137 0 0 0 7.304 2.999 8.142 8.142 0 0 0 6.413-4.607"
    />
  </svg>
);
export default SvgThree;
