import * as React from "react";
import type { SVGProps } from "react";
const SvgForwardSlash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M159.266 506.831h85.347L388.095 93.169h52.64L296.788 506.83h82.864"
    />
  </svg>
);
export default SvgForwardSlash;
