import * as React from "react";
import type { SVGProps } from "react";
const SvgHikerWithBinoculars = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M281.805 32.783A27.569 27.569 0 0 1 301.06 25a27.59 27.59 0 0 1 19.238 7.765m-76.635 157.752v359.012L218.192 575h72.78V391.611m66.583-40.042v197.96L383.026 575h-72.78V391.611m47.309-201.094v120.734h-57.361V136.42m13.427 38.826h-26.855m26.855 43.121h-26.855m26.855 43.141h-26.855m46.738-150.485 47.382 55.424c5.994 7.949 17.282 9.535 25.231 3.541 7.95-5.994 9.536-17.282 3.542-25.231l-56.476-62.304V65.116m-86.686 45.907-47.383 55.424c-5.994 7.949-17.282 9.535-25.231 3.541-7.95-5.994-9.536-17.282-3.542-25.231l56.476-62.304V65.116m46.239-1.733c0 7.588-6.152 13.74-13.741 13.74-7.588 0-13.74-6.152-13.74-13.74 0-7.59 6.152-13.741 13.74-13.741 7.589 0 13.741 6.152 13.741 13.74m41.462 0c0 7.588-6.152 13.74-13.741 13.74-7.588 0-13.74-6.152-13.74-13.74 0-7.59 6.152-13.741 13.74-13.741 7.589 0 13.741 6.152 13.741 13.74"
    />
  </svg>
);
export default SvgHikerWithBinoculars;
