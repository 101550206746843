import * as React from "react";
import type { SVGProps } from "react";
const SvgDesktopWithCodeSymbols = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M277.409 428.462v48.84h-86.94c-14.319 0-27.615 7.159-35.542 19.177l-12.018 18.155h314.26l-12.018-18.155c-7.927-12.018-21.223-19.177-35.543-19.177h-86.939v-48.84H537.46c20.712 0 37.589-16.876 37.589-37.589V123.407c0-20.712-16.877-37.589-37.589-37.589H62.637c-20.712 0-37.588 16.877-37.588 37.589v267.466c0 20.713 16.876 37.589 37.588 37.589zm0 0h.02m-13.55-110.72-79.524-55.232 80.291-54.21m81.06 109.442 79.524-55.232-80.291-54.21m-30.43-13.551L287.66 344.336"
    />
  </svg>
);
export default SvgDesktopWithCodeSymbols;
