import * as React from "react";
import type { SVGProps } from "react";
const SvgUiIcons = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M535.903 110.206h39.146v380.041h-550V110.206H450.76m5.869 116.131 40.45 25.445m-40.45-44.692 40.45-25.445m-60.673 205.844c0-23.814 19.247-43.061 43.06-43.061 23.814 0 43.061 19.247 43.061 43.061 0 23.813-20.226 43.06-43.713 43.06h-24.466m19.9 18.92-20.225-19.247 20.225-19.246M65.17 199.915v-50.889s38.819-7.503 38.819 25.444c0 32.948-11.417 23.814-21.856 25.445m39.143 0 31.969-50.89m0 50.89-31.969-50.89m54.157 50.89v-50.89s28.055-4.567 28.055 14.354c0 18.92-4.894 12.722-10.439 14.027m97.208-4.894v-20.878l21.53-5.22 9.461 18.595 17.615 5.219 17.942-10.765 15.332 16.311-11.417 17.616 4.241 17.615 18.268 10.113-6.198 21.53-20.878-1.305-13.375 12.723v20.878l-21.53 5.219-9.461-18.594-17.615-5.22-17.942 10.765-15.332-16.31 11.417-17.616-4.24-17.616-18.269-10.112 6.199-21.531 20.877 1.305zm7.503 45.344c2.61 10.439 13.049 16.637 23.488 14.353 10.439-2.283 16.637-13.048 14.353-23.487-2.61-10.439-13.048-16.637-23.487-14.354-10.439 2.284-16.637 13.049-14.354 23.488M112.145 352.908v-20.877l21.53-5.22 9.46 18.594 17.616 5.22 17.942-10.765 15.332 16.311-11.418 17.615 4.241 17.616 18.268 10.113-6.198 21.53-20.878-1.305-13.375 12.722v20.878l-21.53 5.22-9.46-18.594-17.616-5.22-17.942 10.765L82.785 431.2l11.418-17.615-4.241-17.616-18.268-10.113 6.198-21.53 20.878 1.305zm7.503 45.345c2.609 10.438 13.048 16.637 23.487 14.353 10.439-2.283 16.637-13.049 14.354-23.487-2.284-10.439-13.049-16.638-23.488-14.354-10.439 2.283-16.637 13.049-14.353 23.488m217.584 36.537 30.338-100.148-91.992 49.911 25.118 13.375-32.948 40.777 18.268 14.679 32.948-40.45zm122.009-218.239c0 9.909-8.033 17.942-17.942 17.942s-17.942-8.033-17.942-17.942 8.033-17.942 17.942-17.942 17.942 8.033 17.942 17.942m71.118-44.692c0 9.909-8.033 17.942-17.942 17.942s-17.942-8.033-17.942-17.942 8.033-17.942 17.942-17.942 17.942 8.033 17.942 17.942m0 89.383c0 9.909-8.033 17.941-17.942 17.941s-17.942-8.032-17.942-17.941c0-9.909 8.033-17.942 17.942-17.942s17.942 8.033 17.942 17.942"
    />
  </svg>
);
export default SvgUiIcons;
