import * as React from "react";
import type { SVGProps } from "react";
const SvgHeadWithNoHair = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M331.916 290.187h-55.897m-88.473.009-54.788-2.221m290.96 0 47.014-5.553M347.835 417.899s-14.437 19.619-45.902 19.619c-31.465 0-45.902-19.619-45.902-19.619m226.178-123.267c0-1.481-11.474-6.294-11.474-7.774 0-132.525-46.645-217.666-164.362-217.666-117.717 0-173.614 86.253-173.614 218.777 0 .37-9.625 3.332-9.625 3.702-15.548 5.183-24.432 28.134-20.36 53.676 4.072 25.543 22.582 46.642 39.98 43.681h.739c35.168 83.661 76.628 141.78 159.178 141.78 104.391 0 127.344-58.119 160.66-141.41 15.177-1.48 29.613-19.619 33.685-43.68 4.072-24.062-2.591-42.942-14.807-51.086m-202.857 8.52c0 20.649-20.551 37.387-45.902 37.387-25.352 0-45.902-16.738-45.902-37.387s20.55-37.388 45.902-37.388c25.351 0 45.902 16.739 45.902 37.388m144.37 0c0 20.649-20.551 37.387-45.902 37.387-25.351 0-45.902-16.738-45.902-37.387s20.551-37.388 45.902-37.388c25.351 0 45.902 16.739 45.902 37.388"
    />
  </svg>
);
export default SvgHeadWithNoHair;
