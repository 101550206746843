import * as React from "react";
import type { SVGProps } from "react";
const SvgBoxPlotChart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M562.661 217.059V575M37.16 563.037V575m0-369.11v202.268m375.357-74.745V575m0-509.55v116.162m75.069-141.584v63.761m0 157.193V575M337.441 289.49V575m0-487.004v136.766M187.304 445.143V575m0-457.536v169.714M562.661 25v33.327M112.228 511.411V575m0-401.478v152.954m150.144-221.727v133.115m0 184.935V575M573.699 58.134H551.61V216.9h22.089zm-75.241 45.559h-22.09v157.385h22.09zm-74.551 78.002h-22.09v151.863h22.09zm-75.242 42.798h-22.089v64.887h22.089zm-75.241 13.115h-22.089v184.997h22.089zm-75.241 49.701h-22.089v158.076h22.089zm-75.242 39.346h-22.089v184.997h22.089zm-74.55 81.454H26.3v154.625h22.09z"
    />
  </svg>
);
export default SvgBoxPlotChart;
