import * as React from "react";
import type { SVGProps } from "react";
const SvgDesktop2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M430.168 412.665h.032M212.371 504.8l11.168-53.957m136.079 0 11.137 53.957M575 378.472H25m451 34.193h.032m45.803 0h.031M57.845 46.669h484.31c18.068 0 32.845 14.774 32.845 32.845v338.484c0 18.071-14.774 32.845-32.845 32.845H57.845C39.777 450.843 25 436.069 25 417.998V79.514c0-18.07 14.774-32.845 32.845-32.845M419 543.48v5.961c0 2.134-3.325 3.89-7.405 3.89H171.527c-4.047 0-7.404-1.756-7.404-3.89v-5.961c0-64.184 254.877-64.184 254.877 0"
    />
  </svg>
);
export default SvgDesktop2;
