import * as React from "react";
import type { SVGProps } from "react";
const SvgQuestionMark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M301.37 494.201c10.093 0 25.003 10.349 26.672 26.719 1.669 16.371-11.046 32.423-28.211 32.423a28.528 28.528 0 0 1-20.098-8.44c-5.318-5.363-8.272-11.375-8.272-20.168v-225.45a13.355 13.355 0 0 1 4.645-10.111 13.348 13.348 0 0 1 10.692-3.08 98.29 98.29 0 0 0 12.874.874c51.893 0 93.931-43.231 91.865-95.52-1.828-47.76-40.529-86.382-88.209-88.289a92.103 92.103 0 0 0-94.885 80.342 13.192 13.192 0 0 1-13.191 11.523h-29.96a13.278 13.278 0 0 1-9.864-4.326 13.273 13.273 0 0 1-3.407-10.217c7.311-75.097 70.647-133.823 147.651-133.823a148.05 148.05 0 0 1 148.366 147.73c.397 96.712-78.832 135.889-110.38 144.472a13.272 13.272 0 0 0-9.775 12.874v93.215a13.431 13.431 0 0 1-13.35 13.351H155.429"
    />
  </svg>
);
export default SvgQuestionMark;
