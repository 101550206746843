import * as React from "react";
import type { SVGProps } from "react";
const SvgLightbulb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M144.52 552.293h114.031c14.02 17.135 50.659 41.125 85.055 0v-11.216h-96.271a7.478 7.478 0 0 1-7.477-7.478v0a7.478 7.478 0 0 1 7.477-7.477h107.487a4.673 4.673 0 0 0 4.673-4.673v0a4.674 4.674 0 0 0-4.673-4.674H247.9a8.041 8.041 0 0 1-8.042-8.042v-.74c0-4.441 3.6-8.042 8.042-8.042h106.454a5.14 5.14 0 0 0 5.141-5.14v0a5.14 5.14 0 0 0-5.141-5.141H248.737a7.945 7.945 0 0 1-7.944-7.945v0a7.944 7.944 0 0 1 7.944-7.944h107.267a5.36 5.36 0 0 0 5.36-5.36v-7.974a5.36 5.36 0 0 0-5.36-5.36H239.858c-5.608-1.869-17.385-9.159-19.628-23.366-3.739-22.744-14.394-73.465-27.105-94.401-13.709-25.236-42.621-80.008-48.603-97.205-9.939-28.575-11.964-67.483 0-98.14 8.724-37.698 51.594-113.842 153.285-116.833 34.583-1.87 113.282 14.02 151.416 92.531 11.527 23.991 27.105 86.739-2.804 145.809-12.01 23.719-48.603 88.045-56.08 152.35h58.886"
    />
  </svg>
);
export default SvgLightbulb;
