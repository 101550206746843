import * as React from "react";
import type { SVGProps } from "react";
const SvgMinusSign = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M392.353 308.927V268.32H146.195v63.361l254.912-1.576a77.567 77.567 0 0 0 52.698-21.178"
    />
  </svg>
);
export default SvgMinusSign;
