import * as React from "react";
import type { SVGProps } from "react";
const SvgPersonFishingOnBoat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 601 601"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="m369.547 223.722 11.168 2.234s2.234-11.473 13.403-8.631c11.168 2.843 4.975 24.064-.914 25.485-.609 2.741-2.437 11.981-2.437 11.981l-1.421 6.701 10.356.711c11.879.203 17.971 14.316 10.559 23.555l-10.66 15.636m-19.191-13.707 19.19 13.809M205.878 25.226v510.606a5.894 5.894 0 0 0-5.89 5.889 5.893 5.893 0 0 0 5.889 5.889l.001 20.814a6.803 6.803 0 0 0 6.802 6.803c10.966 0 5.788-10.357 4.671-13.809M383.66 404.246c-.203-25.687 1.422-60.208 10.864-81.53m-29.139 81.531-6.803-54.32s-7.716 23.758-5.584 54.32m-22.338-145.09-4.772 21.626c-2.133 11.676 3.147 16.042 10.457 16.245h2.133l7.615-.812s-13.504 54.523-10.255 108.03m44.573-83.865-14.925-26.094a7.736 7.736 0 0 1-2.132.305c-4.671 0-8.529-3.859-8.529-8.529 0-4.671.812-4.163 2.132-5.686l-16.245-27.515 2.741-1.828a8.506 8.506 0 0 0 3.554-6.904c0-5.28-4.772-9.341-10.255-8.326-1.726.305-3.249 1.219-4.467 2.437L206.588 25.227m12.997 444.609h300.941s-12.387 18.987-33.709 18.987c-21.321 0-30.053-5.584-21.524-25.383l13.909-32.288h32.897s-1.32 18.378-14.113 18.378v-33.303h6.904l5.28-15.839h-10.966l3.046-9.138h-53.304l-7.513 22.236H226.997s10.457 22.337 27.921 35.536M79.571 469.836h113.412"
    />
  </svg>
);
export default SvgPersonFishingOnBoat;
