import * as React from "react";
import type { SVGProps } from "react";
const SvgHeadWithCurlyHair = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M111.023 306.099v-1.112h-4.073a23.318 23.318 0 0 1-23.32-23.321 23.321 23.321 0 0 1 23.32-23.322 23.322 23.322 0 0 1 2.222-46.273h2.221v-.739a23.692 23.692 0 0 1-18.138-22.952 23.317 23.317 0 0 1 23.32-22.95c2.631.005 5.248.378 7.775 1.11a23.698 23.698 0 0 1-12.586-20.731 22.946 22.946 0 0 1 12.683-20.904 22.954 22.954 0 0 1 24.335 2.395l1.11-1.48a23.693 23.693 0 0 1-9.994-19.25 23.322 23.322 0 0 1 23.32-23.32 23.694 23.694 0 0 1 20.36 12.214l.741-.739a21.48 21.48 0 0 1-1.482-7.033 23.323 23.323 0 0 1 44.053-10.737l2.961-.739c0-.74-.371-1.481-.371-2.592a23.325 23.325 0 0 1 23.323-22.95 22.95 22.95 0 0 1 22.211 16.658h2.219a23.321 23.321 0 0 1 40.633-18.352 23.326 23.326 0 0 1 4.798 8.568c.972 3.164 1.258 6.5.842 9.784h1.112a23.32 23.32 0 0 1 22.21-16.658 23.322 23.322 0 0 1 23.321 22.95 11.467 11.467 0 0 1-.371 3.702l2.592.741a22.95 22.95 0 0 1 21.101-13.697 23.321 23.321 0 0 1 23.321 23.322 16.652 16.652 0 0 1-1.11 5.922h1.11a24.062 24.062 0 0 1 19.619-10.735 23.325 23.325 0 0 1 16.492 6.83 23.326 23.326 0 0 1 6.83 16.492 22.95 22.95 0 0 1-11.476 19.989l1.481 1.851a22.216 22.216 0 0 1 15.178-5.923 23.32 23.32 0 0 1 23.32 23.321 22.961 22.961 0 0 1-3.853 12.671 22.955 22.955 0 0 1-10.213 8.43 24.81 24.81 0 0 1 8.884-1.481 22.954 22.954 0 0 1 24.698 20.093 22.946 22.946 0 0 1-4.445 16.578 22.949 22.949 0 0 1-14.7 8.861v1.112h1.851a23.323 23.323 0 0 1 0 46.641h2.592a23.323 23.323 0 0 1 0 46.643 22.2 22.2 0 0 1-10.735-1.851v2.222h.369M472.69 168.02h1.11M340.535 441.205s-14.808 19.99-46.273 19.99c-31.465 0-45.902-19.99-45.902-19.99m233.215-126.602c0-1.481-22.952-5.182-22.952-6.663 0-137.337-95.137-138.817-159.178-138.817s-163.989-.002-163.989 137.335l-25.913 5.184c-15.918 5.553-25.172 29.245-20.73 55.527 4.442 26.283 22.951 48.493 41.09 45.161h.741c36.648 86.252 79.588 146.592 165.099 146.592 108.093 0 131.786-59.969 166.213-146.221 15.918-1.851 30.724-20.73 34.796-45.532 4.072-24.802-2.591-44.052-15.177-52.566"
    />
  </svg>
);
export default SvgHeadWithCurlyHair;
