import * as React from "react";
import type { SVGProps } from "react";
const SvgRollercoaster = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M206.82 269.451c-17.053-39.022-101.622-89.763-159.13-23.234-77.87 90.085 62.138 217.428 156.735 92.462C317.164 215.074 437.506 106.961 575 307.913v155.144m-450.353-.001v-72.941m146.945 72.941V270.111m146.945 192.945V196.332M25 463.056V303.437m59.249 10.783v-95.583m69.589 95.583v-91.965m272.086-77.56a7.75 7.75 0 1 1-15.5 0 7.75 7.75 0 0 1 15.5 0m-31.001 8.213c-6.421 0-11.625 5.205-11.625 11.625v19.376h69.752v-7.751c0-12.841-10.41-23.25-23.251-23.25v15.5h-3.875v-4.65a7.75 7.75 0 0 0-15.501 0v4.65h-3.875v-3.875c0-6.42-5.205-11.625-11.625-11.625m4.478 39.397a4.026 4.026 0 1 1-8.052 0 4.026 4.026 0 0 1 8.052 0m45.597 0a4.027 4.027 0 1 1-8.053-.001 4.027 4.027 0 0 1 8.053.001m-113.603-27.651a7.752 7.752 0 0 1-13.871 6.92 7.75 7.75 0 0 1 13.871-6.92m-24.074 21.189c-5.746 2.866-8.079 9.847-5.213 15.592l8.65 17.338 62.415-31.139-3.46-6.935c-5.732-11.49-19.694-16.158-31.184-10.426l6.919 13.871-3.467 1.729-2.076-4.161a7.75 7.75 0 0 0-13.87 6.92l2.076 4.161-3.468 1.73-1.73-3.467c-2.866-5.746-9.847-8.08-15.592-5.213m21.595 33.254a4.027 4.027 0 1 1-7.207 3.594 4.027 4.027 0 0 1 7.207-3.594m40.801-20.355a4.026 4.026 0 1 1-7.205 3.593 4.026 4.026 0 0 1 7.205-3.593m-112.979 14.871a7.75 7.75 0 1 1-12.01 9.8 7.75 7.75 0 0 1 12.01-9.8m-18.83 25.961c-4.975 4.058-5.717 11.382-1.659 16.357l12.248 15.013 54.048-44.092-4.899-6.006c-8.117-9.95-22.763-11.435-32.713-3.318l9.798 12.01-3.003 2.45-2.939-3.603a7.75 7.75 0 0 0-12.011 9.798l2.94 3.603-3.003 2.45-2.45-3.003c-4.058-4.975-11.382-5.718-16.357-1.659m28.375 27.696a4.027 4.027 0 1 1-6.242 5.09 4.027 4.027 0 0 1 6.242-5.09m35.331-28.822a4.026 4.026 0 1 1-6.24 5.089 4.026 4.026 0 0 1 6.24-5.089"
    />
  </svg>
);
export default SvgRollercoaster;
