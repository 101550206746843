import * as React from "react";
import type { SVGProps } from "react";
const SvgMap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M164.986 190.674v377.739h286.055V84.849H164.986l286.055-53.263H164.986m205.172 484.202v-88.062m0 0-27.993 27.993m27.993-27.993 27.993 27.993m-60.86-234.571c0 16.175-13.113 29.288-29.288 29.288-16.176 0-29.288-13.113-29.288-29.288 0-16.176 13.112-29.289 29.288-29.289 16.175 0 29.288 13.113 29.288 29.289m33.108 40.682-62.396 97.065-62.09-96.625c-7.816-11.79-12.382-25.93-12.382-41.145 0-41.144 33.349-74.472 74.472-74.472 41.122 0 74.472 33.35 74.472 74.472 0 15.018-4.457 29.025-12.098 40.727zm-189.385-87.313c0 8.851-7.176 16.027-16.028 16.027-8.851 0-16.027-7.176-16.027-16.027 0-8.852 7.176-16.028 16.027-16.028 8.852 0 16.028 7.176 16.028 16.028"
    />
  </svg>
);
export default SvgMap;
