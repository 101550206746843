import * as React from "react";
import type { SVGProps } from "react";
const SvgZero = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M299.961 506.659c-37.036-1.181-94.202-13.938-125.969-63.86-23.118-36.3-36.564-81.498-36.564-136.697 0-103.939 48.752-212.761 162.611-212.761 113.86 0 162.533 108.822 162.533 212.761 0 37.56-4.011 115.358-56.93 168.351m-105.603-306.15c-59.131 0-80.204 62.207-80.204 132.051 0 69.845 21.073 132.681 80.204 132.681 59.132 0 80.126-62.758 80.126-132.681s-21.073-132.051-80.126-132.051"
    />
  </svg>
);
export default SvgZero;
